import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import MuiTypography from "@material-ui/core/Typography"
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { OrganisationSurveyTag, Survey, Tag, Team } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps, type ViewComponentState } from "core/components/base/ViewComponent"
import Button from "lib/ui/components/form/Button"
import Dropdown from "lib/ui/components/form/Dropdown"
import TextField from "lib/ui/components/form/TextField"
import CardLayout from "lib/ui/components/layout/CardLayout"
import Grid from "lib/ui/components/layout/grid/Grid"
import LanguageSelector from "lib/ui/components/settings/LanguageSelector"
import { ViewComponent } from "../../../base/ViewComponent"
import QuestionsProgress from "../questions/QuestionsProgress"

interface Props extends ViewComponentProps {
  background?: string
  maxQuestions: number
  survey: Survey
  maxPage: number
  language: string
  logo: string
  organisationSurveyTags: string[]
  selectedTeam?: string
  teams: Team[]
  enabledTags: Tag[]
  enabledTagCategories: string[]
  email: string
  validEmailDomains: string[]
  emailError: boolean
  emailErrorDescription?: string
  firstName: string
  lastName: string
  tagSelection: { [category: string]: string }
  nextEnabled: boolean
  onNext: () => void
  onSetLanguage: (language: string) => void
  onSelectTeam: (team?: string) => void
  onSetEmail: (email: string) => void
  onSetFirstName: (firstName: string) => void
  onSetLastName: (lastName: string) => void
  onLogout: () => void
  onSetTagSelection: (tagId?: string) => void
  onShowNestedTeams: () => void
}

interface State extends ViewComponentState {
  showNestedTeamsDialog: boolean
  tagSelection: { [category: string]: string }
}

export class PersonalDetailsPageView extends ViewComponent<Props & WithStyles<typeof styles>, State> {
  get componentName() {
    return ["survey", "pages", "personal_details", "PersonalDetailsPageView"]
  }

  constructor(props: Props & WithStyles<typeof styles>) {
    super(props)

    this.state = {
      showNestedTeamsDialog: false,
      tagSelection: {}
    }
  }

  render() {
    super.render()

    return this.isMobile ? this.renderMobile() : this.renderDesktop()
  }

  private renderMobile() {
    const { classes } = this.props

    return (
      <div className={classes.mobileLayout}>
        {this.renderHeaderMobile()}
        {this.renderMain()}
      </div>
    )
  }

  private renderDesktop() {
    const { background, classes } = this.props

    return (
      <CardLayout
        maxWidth="50rem"
        width="100%"
        header={this.renderHeaderDesktop()}
        backgroundImage={this.isMobile ? undefined : background}
        background={this.theme.background}
        headerBackgroundColor={this.theme.colors.windowColor}
      >
        <div className={classes.cardContent}>{this.renderMain()}</div>
      </CardLayout>
    )
  }

  private renderHeaderDesktop() {
    const { classes, language, onSetLanguage, survey, maxPage } = this.props

    return (
      <div className={classes.topContainerOuter}>
        <div className={classes.topContainerDesktop}>
          <div>
            <MuiTypography color="textSecondary">
              {this.txt(survey.title)} &bull; {0}/{maxPage + 1}
            </MuiTypography>

            {this.renderTitle()}
          </div>
          <div>
            {this.renderLogo()}

            <LanguageSelector
              className={classes.languageSelector}
              id="language-selector"
              language={language}
              setLanguage={onSetLanguage}
            />
          </div>
        </div>

        <Divider className={classes.hr} />
      </div>
    )
  }

  private renderHeaderMobile() {
    const { classes, language, onSetLanguage, survey, maxPage } = this.props

    return (
      <div>
        <div className={classes.topContainerMobile}>
          {this.renderLogo()}

          <LanguageSelector
            className={classes.languageSelector}
            id="language-selector"
            language={language}
            setLanguage={onSetLanguage}
          />
        </div>
        <MuiTypography>{this.txt(survey.title)}</MuiTypography>
        <MuiTypography color="textSecondary">{`0/${maxPage + 1}`}</MuiTypography>

        <Divider className={classes.hr} />
      </div>
    )
  }
  private renderLogo() {
    const { logo, classes } = this.props

    return <img alt="Hintsa" className={classes.logo} src={logo} />
  }

  private renderMain() {
    super.render()

    const { classes } = this.props

    return (
      <>
        <Box className={classes.personalDetails}>{this.renderPersonalDetails()}</Box>
        {this.renderCommandButtons()}
      </>
    )
  }

  private renderTitle() {
    const { classes } = this.props

    return (
      <MuiTypography className={classes.categoryTitle} component="h1" variant="h2">
        {this.txt("background")}
      </MuiTypography>
    )
  }

  private renderPersonalDetails() {
    const { organisationSurveyTags, classes } = this.props

    const askName = organisationSurveyTags.includes(OrganisationSurveyTag.AskUserName)
    const askEmail = organisationSurveyTags.includes(OrganisationSurveyTag.AskUserEmail)
    const askTeam = organisationSurveyTags.includes(OrganisationSurveyTag.SelectTeam)
    const askTags = organisationSurveyTags.includes(OrganisationSurveyTag.SelectTags)

    return (
      <Grid container>
        {askName && (
          <>
            <Grid className={classes.gridPaddingRight} sm={6} xs={12}>
              {this.renderFirstName()}
            </Grid>
            <Grid sm={6} xs={12}>
              {this.renderLastName()}
            </Grid>
          </>
        )}

        {askEmail && <Grid xs={12}>{this.renderEmail()}</Grid>}

        {askTeam && (
          <>
            <Grid sm={6} xs={12}>
              {this.renderTeams()}
            </Grid>
            <Grid sm={6} xs={12} />
          </>
        )}

        {askTags && (
          <>
            <Grid sm={6} xs={12}>
              {this.renderTags()}
            </Grid>
            <Grid sm={6} xs={12} />
          </>
        )}
      </Grid>
    )
  }
  private renderFirstName() {
    const { firstName, onSetFirstName } = this.props

    return (
      <TextField
        margin
        fullWidth
        value={firstName}
        label={this.txt("firstName")}
        length={200}
        onChange={onSetFirstName}
      />
    )
  }

  private renderLastName() {
    const { lastName: lastNmae, onSetLastName: onSetLasttName } = this.props
    return (
      <TextField
        margin
        fullWidth
        value={lastNmae}
        label={this.txt("lastName")}
        length={200}
        onChange={onSetLasttName}
      />
    )
  }

  private renderEmail() {
    const { email, emailError, emailErrorDescription, onSetEmail } = this.props
    return (
      <TextField
        margin
        showErrorOnFocus={false}
        value={email}
        label={this.txt("email")}
        required
        error={emailError}
        helperText={emailErrorDescription}
        length={320}
        fullWidth
        onChange={onSetEmail}
      />
    )
  }

  private renderTeams() {
    const { onSelectTeam, selectedTeam, teams, onShowNestedTeams, classes } = this.props

    if (teams.length === 0) return false

    const teamOptions = (teams ?? []).map(team => ({
      label: team.name,
      value: team.id!.toString()
    }))

    return (
      <div className={classes.teamsContainer}>
        {this.areTeamOptionsFlat() ? (
          <Dropdown
            value={selectedTeam}
            label={this.txt("selectTeam")}
            options={teamOptions ?? []}
            id="personal_details_team_selection"
            emptySelectionLabel={this.txt("full_report")}
            onChange={onSelectTeam}
            fullWidth
          />
        ) : (
          <Dropdown
            value={selectedTeam}
            label={this.txt("selectTeam")}
            options={teamOptions ?? []}
            id="personal_details_team_selection"
            emptySelectionLabel={this.txt("full_report")}
            onOpen={onShowNestedTeams}
            renderValue={(value: string) => <div>{teams.find(team => team.id!.toString() === value)?.name}</div>}
            fullWidth
          />
        )}
      </div>
    )
  }

  private renderTags() {
    const { classes, enabledTags, enabledTagCategories, tagSelection, onSetTagSelection } = this.props

    if (!enabledTags || !enabledTagCategories) return false

    const enabledTagsByCategory: { [key: string]: Tag[] } = {}

    enabledTags.forEach(tag => {
      if (tag.category_name && enabledTagCategories.includes(tag.category_name)) {
        enabledTagsByCategory[tag.category_name] ??= []
        enabledTagsByCategory[tag.category_name].push(tag)
      }
    })

    const activeTagCategories = enabledTagCategories.filter(
      enabledTagCategory => !!enabledTagsByCategory[enabledTagCategory]?.length
    )

    return activeTagCategories.map(category => {
      const tags = enabledTagsByCategory[category].slice().sort(this.sortTags)

      return (
        <Grid xs={12} key={category} className={classes.tagsContainer}>
          <Dropdown
            label={this.getLocalizedTagCategoryName(tags[0])}
            value={tagSelection[category]}
            options={tags.map(tag => ({
              label: this.getLocalizedTagName(tag),
              value: tag.id
            }))}
            onChange={onSetTagSelection}
            fullWidth
          />
        </Grid>
      )
    })
  }

  private getLocalizedTagName(tag: Tag) {
    const { language } = this.props

    if (!tag) return ""

    // Prefer correct translation, secondarily use English translation, finally use the legacy name property that should
    // have an english name or tag key
    return tag.name_translations?.[language] ?? tag.name_translations?.["en"] ?? tag.name
  }

  private getLocalizedTagCategoryName(tag: Tag) {
    const { language } = this.props

    if (!tag) return ""

    // Prefer correct translation, secondarily use English translation, finally use the category_name property that
    // should have an english name or category key
    return tag.category_name_translations?.[language] ?? tag.category_name_translations?.["en"] ?? tag.category_name
  }

  private sortTags = (a: Tag, b: Tag) => {
    switch (a.category_name?.toLowerCase()) {
      // Todo: Add special handling for common/global tags such as age
      default:
        return a.name.localeCompare(b.name)
    }
  }

  private renderCommandButtons() {
    const { classes, nextEnabled, onNext, maxPage } = this.props

    return (
      <div
        id="buttonContainer"
        className={this.isMobile ? classes.buttonContainerMobile : classes.buttonContainerDesktop}
      >
        <Button disabled={true} variant="secondary">
          {this.txt("core", "components", "ui", "form", "previous")}
        </Button>

        {!this.isMobile && <QuestionsProgress progress={0} maxItems={maxPage + 1} />}

        <Button onClick={onNext} disabled={!nextEnabled}>
          {this.txt("core", "components", "ui", "form", "next")}
        </Button>
      </div>
    )
  }

  private areTeamOptionsFlat() {
    const { teams } = this.props

    return !teams.find(team => team.parent)
  }
}

const styles = () =>
  createStyles({
    birthYear: {
      // Match the height of the input to rest of the form
      "& .MuiInput-root": { marginTop: "calc(0.9* 1.43rem) !important" }
    },
    progress: {
      fontSize: "16px",
      width: "6em",
      marginTop: "1em",
      textAlign: "right"
    },
    hr: {
      marginBottom: "1rem",
      marginTop: "1rem"
    },
    categoryTitle: {
      fontWeight: "bold"
    },
    languageSelector: {
      justifyContent: "flex-end"
    },
    cardContent: {
      padding: "0 2rem 2rem 2rem",
      display: "flex",
      flexDirection: "column"
    },
    personalDetails: {
      paddingLeft: 0,
      padding: "1rem 0 2rem 0"
    },
    title: {
      marginTop: 0,
      fontSize: "20px"
    },
    buttonContainerDesktop: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // "invisible" padding to improve auto scroll behavior
      paddingBottom: "1rem",
      marginBottom: "-1rem"
    },
    buttonContainerMobile: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      paddingBottom: "1rem",
      alignItems: "flex-end"
    },
    topContainerOuter: {
      margin: "2.5rem 2.5rem 0 2.5rem"
    },
    topContainerDesktop: {
      display: "flex",
      justifyContent: "space-between"
    },
    topContainerMobile: {
      justifyContent: "space-between",
      minHeight: "4em",
      display: "flex"
    },
    topContainerColumn: {
      display: "flex",
      flexDirection: "column"
    },
    logo: {
      width: 110,
      height: 35
    },
    mobileLayout: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      margin: "1.5rem",
      flexGrow: 1
    },
    radioQuestion: {
      marginLeft: "1rem"
    },
    gridPaddingRight: {
      paddingRight: "1rem"
    },
    teamsContainer: {
      width: "100%"
    },
    tagsContainer: {
      width: "100%"
    },
    teamsButton: {
      alignSelf: "flex-end",
      marginLeft: "0.5rem",
      marginBottom: "0.5rem"
    }
  })

export default withStyles(styles)(PersonalDetailsPageView)
