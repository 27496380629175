import moment from "moment"
import { Moment } from "moment"
import { style } from "typestyle"

import { PlannedSurvey, Survey } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Button from "lib/ui/components/form/Button"
import CardLayout from "lib/ui/components/layout/CardLayout"

import { ViewComponent } from "../../../base/ViewComponent"

interface SelectSurveyPageViewProps extends ViewComponentProps {
  language?: string
  scheduledSurveys: PlannedSurvey[]
  logo: string

  onLogout: () => void
  onSetLanguage: (language: string) => void
  onSelectSurvey: (plannedSurveyId: string) => void
}

export default class SelectSurveyPageView extends ViewComponent<SelectSurveyPageViewProps, {}> {
  get componentName() {
    return ["survey", "pages", "select_survey", "SelectSurveyPageView"]
  }

  private get logoClass() {
    const { logo } = this.props

    return style({
      backgroundImage: `url(${logo})`,
      backgroundSize: "cover",
      width: "12.75rem",
      height: "2.25rem",
      marginBottom: "2rem"
    })
  }

  private get buttonContainerClassMobile() {
    return style({
      display: "flex",
      flexGrow: 1,
      alignItems: "flex-end",
      paddingBottom: "2rem"
    })
  }

  private get containerClass() {
    return style({
      display: "flex",
      flexDirection: "column",
      height: "100%",
      minWidth: this.isMobile ? "18rem" : undefined,
      margin: this.isMobile ? "1.5rem" : "2.5rem"
    })
  }

  get welcomeClassMobile() {
    return style({
      marginBottom: "3em",
      marginTop: "3em"
    })
  }

  get welcomeClassDesktop() {
    return style({
      marginBottom: "3em",
      marginTop: "3em"
    })
  }

  get welcomeTitleClass() {
    return style({
      fontSize: "18px"
    })
  }

  get availableSurveysClassDesktop() {
    return style({
      alignSelf: "center"
    })
  }

  render() {
    super.render()

    return this.isMobile ? this.renderMobile() : this.renderDesktop()
  }

  private renderMobile() {
    return this.renderMain()
  }

  private renderDesktop() {
    return (
      <CardLayout width="50rem" background={this.theme.background} headerBackgroundColor={this.theme.colors.primary}>
        {this.renderMain()}
      </CardLayout>
    )
  }

  private renderMain() {
    if (this.props.scheduledSurveys.length === 0) {
      return this.renderNoSurvey()
    } else {
      return this.isMobile ? this.renderSelectionMobile() : this.renderSelectionDesktop()
    }
  }

  private renderLogo() {
    return <div className={this.logoClass} />
  }

  private renderSelectionMobile() {
    return (
      <div className={this.containerClass}>
        {this.renderLogo()}

        <div className={this.welcomeClassMobile}>
          <div className={this.welcomeTitleClass}>{this.txt("select_survey")}</div>
        </div>
        <div>{this.txt("available_surveys")}</div>
        <div>{this.renderSurveys()}</div>
      </div>
    )
  }

  private renderSelectionDesktop() {
    return (
      <div className={this.containerClass}>
        <h3 className={this.welcomeClassDesktop}>{this.txt("select_survey")}</h3>
        <div>
          <div className={this.availableSurveysClassDesktop}>{this.txt("available_surveys")}</div>
          <div>{this.renderSurveys()}</div>
        </div>
      </div>
    )
  }

  private renderNoSurvey() {
    return (
      <div className={this.containerClass}>
        <div className={this.welcomeClassMobile}>
          <div>{this.txt("no_surveys_available")}</div>
        </div>
        <div className={this.isMobile ? this.buttonContainerClassMobile : undefined}>
          <Button fullWidth={this.isMobile} onClick={this.props.onLogout}>
            {this.txt("core", "components", "ui", "form", "logout")}
          </Button>
        </div>
      </div>
    )
  }

  private renderSurveys() {
    const surveyContainerClass = style({
      borderColor: this.theme.colors.emphasizeColor2.toString(),
      color: this.theme.colors.emphasizeColor2.toString(),
      borderStyle: "solid",
      padding: "2rem",
      cursor: "pointer",
      transition: "color 0.2s ease; background-color 0.2s ease",
      minWidth: "18rem",
      width: "100%",
      margin: "1rem 0",
      $nest: {
        "&:hover": {
          borderColor: this.theme.colors.emphasizeColor2.lighten(0.3, true).toHexString(),
          color: this.theme.colors.emphasizeColor2.lighten(0.3, true).toHexString()
        }
      }
    })

    const surveyCompleted = style({
      color: this.theme.colors.emphasizeColor2.lighten(0.3, true).toHexString()
    })

    return this.props.scheduledSurveys.map(plannedSurvey => {
      const organisationSurvey = plannedSurvey.organisation_survey
      const surveyTitleText = organisationSurvey?.title ? `${organisationSurvey.title}, ` : ""

      // when a survey is closed, we give it a ends_on date; until a survey is closed, we give
      // it an ended date of "100 years in the future" (so date-range-based SQL queries still
      // work for reporting). Anything ending after the year 2100 is basically ongoing (not closed)
      const surveyUnEndedDate = moment("01-01-2100", "MM-DD-YYYY")
      const surveyEndDate =
        organisationSurvey?.ends_on.year < surveyUnEndedDate.year
          ? organisationSurvey.ends_on.format(this.localization.dateFormat)
          : ""
      const surveyRangeDesc = surveyEndDate
        ? `${organisationSurvey.started_at.format(this.localization.dateFormat)} - ${surveyEndDate}`
        : `started ${organisationSurvey.started_at.format(this.localization.dateFormat)}`
      const scheduleText = organisationSurvey?.title ? ` (${surveyRangeDesc})` : ""
      return (
        <div
          className={surveyContainerClass}
          key={plannedSurvey.id}
          onClick={() => {
            this.props.onSelectSurvey(plannedSurvey.id!)
          }}
        >
          <div>{`${surveyTitleText}${this.txt((plannedSurvey.survey as Survey).title)}${scheduleText}`}</div>
          <div className={surveyCompleted}>{this.showLastCompletedDate(plannedSurvey)}</div>
        </div>
      )
    })
  }

  private showLastCompletedDate(plannedSurvey: PlannedSurvey) {
    // note: this is also repeated in the ThankYouPageView
    if (!plannedSurvey?.last_completed) {
      return this.showLastStartedDate(plannedSurvey)
    }
    return ` (${this.txt("last_completed", { date: this.renderDate(plannedSurvey.last_completed) })})`
  }

  private showLastStartedDate(plannedSurvey: PlannedSurvey) {
    if (!plannedSurvey?.last_started) {
      return null
    }
    return ` (${this.txt("last_started", { date: this.renderDate(plannedSurvey.last_started) })})`
  }

  private renderDate(this_date: Moment) {
    if (!this_date) return null
    // write the date out using the current locale
    return moment(this_date).format(this.localization.dateFormat)
  }
}
