import moment, { type Moment } from "moment"

import { DocumentConverter } from "core/modules/state/documentconverter/DocumentConverter"
import { Doc, Link } from "core/modules/state/model/Model"

export interface DocumentValueConverter {
  // If set to false this value will not be exported for linked documents
  exportForLinked: boolean
  converterType?: string
  target?: string

  import(document: Doc, key: string, documentConverter: DocumentConverter): void
  export(document: Doc, key: string, documentConverter: DocumentConverter): void
}

export class DocumentValueConverterImpl implements DocumentValueConverter {
  get exportForLinked(): boolean {
    return true
  }

  import(document: Doc, key: string, documentConverter: DocumentConverter) {
    throw new Error("Not implemented")
  }

  export(document: Doc, key: string, documentConverter: DocumentConverter) {
    throw new Error("Not implemented")
  }
}

export type ConversionTypes = "none" | "inline" | "links_only" | "linked_document"
export interface LinkConverterOptions {
  typeKey?: string
  isCompactDocument?: boolean
  exportType?: ConversionTypes
  importType?: ConversionTypes
  conversion?: ConversionTypes
  idProperty?: string
}

export class LinkConverter extends DocumentValueConverterImpl {
  converterType = "LinkConverter"

  target: string
  protected typeKey?: string
  protected exportType?: string
  protected importType?: string
  protected idProperty?: string

  get exportForLinked(): boolean {
    return false
  }

  constructor(target: string, options: LinkConverterOptions = {}) {
    super()
    this.target = target
    this.typeKey = options.typeKey
    this.exportType = options.exportType || options.conversion
    this.importType = options.importType || options.conversion
    this.idProperty = options.idProperty
  }

  import(parentDocument: Doc, key: string, documentConverter: DocumentConverter) {
    if (parentDocument[key] === undefined || parentDocument[key] === null) return

    let documentToImport = parentDocument[key] as Link
    if (!documentToImport.id && this.importType !== "inline") {
      throw new Error("Cannot import document without id. " + key)
    }

    // For some documents, their type is given in one of parent document's properties.
    documentToImport.__type = this.target === "Unknown" ? (parentDocument[this.typeKey!] as string) : this.target

    if (this.importType === "links_only") {
      this.replaceValueWithLink(documentToImport, parentDocument, key)
    } else if (this.importType === "inline") {
      parentDocument[key] = documentConverter.importDocument(documentToImport, documentToImport.__type, true)
    } else {
      let linkedDocument: Doc = documentConverter.importDocument(documentToImport, documentToImport.__type)
      this.replaceValueWithLink(linkedDocument, parentDocument, key)
    }
  }

  export(parentDocument: Doc, key: string, documentConverter: DocumentConverter) {
    if (!parentDocument[key] || !this.exportType) return

    if (this.exportType === "inline") {
      parentDocument[key] = documentConverter.exportLinkedDocument(parentDocument[key] as Link)
    }
  }

  protected replaceValueWithLink(linkedDocument: Link, parentDocument: Doc, key: string) {
    parentDocument[key] = {
      __type: linkedDocument.__type,
      id: linkedDocument.id
    }
  }
}

export class LinkArrayConverter extends LinkConverter {
  converterType = "LinkArrayConverter"

  constructor(target: string, options: LinkConverterOptions = <LinkConverterOptions>{}) {
    super(target, options)
  }

  import(parentDocument: Doc, key: string, documentConverter: DocumentConverter) {
    const links = parentDocument[key] as any[]
    if (!links?.length) return

    for (let i = 0; i < links.length; i++) {
      const documentToImport = links[i]
      if (this.idProperty) {
        documentToImport.id = documentToImport[this.idProperty]
      }

      if (!documentToImport.id && this.importType !== "inline")
        throw new Error("Cannot import document without id. " + key)

      // For some documents, their type is given in one of parent document's properties.
      documentToImport.__type = this.target === "Unknown" ? parentDocument[this.typeKey!] : this.target

      if (this.importType === "links_only") {
        this.replaceArrayValueWithLink(documentToImport, parentDocument, key, i)
      } else if (this.importType === "inline") {
        links[i] = documentConverter.importDocument(documentToImport, documentToImport.__type, true)
      } else {
        const linkedDocument: Doc = documentConverter.importDocument(documentToImport, documentToImport.__type)
        this.replaceArrayValueWithLink(linkedDocument, parentDocument, key, i)
      }
    }
  }

  export(document: Doc, key: string, documentConverter: DocumentConverter) {
    const links = document[key] as any[]
    if (this.importType === "none" || !links?.length) return

    for (let i = 0; i < links.length; i++) {
      if (this.importType === "links_only") {
        links[i] = { id: links[i] }
      } else if (this.importType === "inline") {
        links[i] = documentConverter.exportLinkedDocument(links[i])
      }
    }
  }

  protected replaceArrayValueWithLink(linkedDocument: Doc, parentDocument: Doc, key: string, index: number) {
    ;(parentDocument[key] as Link[])[index] = {
      __type: linkedDocument.__type,
      id: linkedDocument.id
    }
  }
}

export class DateTimeValueConverter extends DocumentValueConverterImpl {
  import(document: Doc, key: string) {
    if (!document[key]) return

    document[key] = moment.utc(document[key])
  }

  export(document: Doc, key: string) {
    if (!document[key]) return

    let value = <moment.Moment>document[key]
    document[key] = value.toJSON()
  }
}

export class DateValueConverter extends DocumentValueConverterImpl {
  constructor(
    private importFormat: string = "YYYYMMDD",
    private exportFormat: string = "YYYYMMDD"
  ) {
    super()
  }

  import(document: Doc, key: string) {
    if (!document[key]) return

    document[key] = moment.utc(document[key], this.importFormat)
  }

  export(document: Doc, key: string) {
    if (!document[key]) return

    document[key] = (document[key] as Moment).format(this.exportFormat)
  }
}

export class UnixDateConverter extends DocumentValueConverterImpl {
  import(document: Doc, key: string) {
    try {
      document[key] = moment.unix(document[key] as number)
    } catch (e) {}
  }
}
