import { BaseModuleWithAppName } from "core/controller/Module"
import { CoreApi, RestApiCallParameters } from "core/modules/api/CoreApi"
import { ProgressManager } from "core/modules/progress/ProgressManager"
import { ConversionMap } from "core/modules/state/conversionmap/ConversionMap"
import { DocumentValueConverter } from "core/modules/state/conversionmap/ValueConverters"
import { DocumentConverter } from "core/modules/state/documentconverter/DocumentConverter"
import { Doc, HttpError, defaultDocumentId } from "core/modules/state/model/Model"
import { ModelManager } from "core/modules/state/model/ModelManager"
import { ViewContainerManager } from "core/modules/state/model/ViewContainerManager"
import { Store } from "core/modules/store/Store"
import type { OptionalKeys, RequireKeys } from "lib/utils/TypeUtils"

export interface ViewParameters extends RequireKeys<Partial<RestApiCallParameters>, "type"> {
  itemsKey?: string // Where to look for items array when importing the view
}

export interface CoreActions {
  // Event hanlders

  createDocumentBefore: ((parameters: RestApiCallParameters) => boolean)[] // TBD
  createDocumentAfter: (<T extends Doc>(document: T, parameters: RestApiCallParameters) => void)[]

  updateDocumentBefore: () => void[] // TBD
  updateDocumentAfter: (<T extends Doc>(
    document: T,
    previousDocuemnt: T | undefined,
    parameters: Partial<RestApiCallParameters<T>>
  ) => void)[] // TBD

  removeDocumentBefore: () => void[] // TBD
  removeDocumentAfter: (<T extends Doc>(document: T, parameters: RestApiCallParameters) => void)[]

  getDocumentBefore: () => void[] // TBD
  getDocumentDuring: (<T extends Doc>(document: T, parameters: Partial<RestApiCallParameters<T>>) => T)[]
  getDocumentAfter: (<T extends Doc>(document: T, parameters: RestApiCallParameters) => void)[]

  getViewBefore: () => void[] // TBD
  getViewAfter: () => void[] // TBD

  getDocumentLocal<T extends Doc>(id?: string | number, type?: string): T | undefined
  getAllDocumentsLocal<T extends Doc>(type: string): T[]
  setDocumentLocal<T extends Doc>(doc: T): void
  setDocumentsLocal<T extends Doc>(doc: T[]): void
  getDefaultDocumentLocal<T extends Doc>(type: string): T | undefined
  setDefaultDocumentLocal<T extends Doc>(doc: OptionalKeys<T, "id">): void
  removeDocumentLocal<T extends Doc>(doc: T): void
  getViewLocal<T extends Doc>(viewName: string): ViewContainerManager<T>
  getDocument<T extends Doc>(api: CoreApi, parameters: RestApiCallParameters<T>): Promise<T | undefined>
  getView<T extends Doc>(api: CoreApi, parameters: ViewParameters): Promise<ViewContainerManager<T>>
  invalidateView(viewName: string, invalidationType: "unload" | "invalidateWhenUsed"): void
  invalidateAllViews(invalidationType: "unload" | "invalidateWhenUsed"): void
  createDocument<T extends Doc>(
    api: CoreApi,
    parameters: Partial<RestApiCallParameters<Partial<T>>>
  ): Promise<T | undefined>
  updateDocument<T extends Doc>(
    api: CoreApi,
    document: T,
    parameters: Partial<RestApiCallParameters<T>>
  ): Promise<T | undefined>
  removeDocument(api: CoreApi, parameters: Partial<RestApiCallParameters>): Promise<Response | undefined>
  importDocument<T extends Doc>(documentRaw: T, documentType: string): T | undefined
}

export class CoreActionsModule extends BaseModuleWithAppName implements CoreActions {
  createDocumentBefore = [] as ((parameters: RestApiCallParameters) => boolean)[]
  createDocumentAfter = [] as (<T extends Doc>(document: T, parameters: RestApiCallParameters) => void)[]
  updateDocumentBefore
  updateDocumentAfter = [] as (<T extends Doc>(
    document: T,
    previousDocument: T | undefined,
    parameters: Partial<RestApiCallParameters<T>>
  ) => void)[]
  removeDocumentBefore
  removeDocumentAfter = [] as (<T extends Doc>(document: T, parameters: RestApiCallParameters) => void)[]
  getDocumentBefore
  getDocumentDuring = [] as (<T extends Doc>(document: T, parameters: Partial<RestApiCallParameters<T>>) => T)[]
  getDocumentAfter = [] as (<T extends Doc>(document: T, parameters: RestApiCallParameters) => void)[]
  getViewBefore
  getViewAfter

  declare conversionMap: ConversionMap
  declare documentConverter: DocumentConverter
  declare modelManager: ModelManager
  declare progressManager: ProgressManager
  declare store: Store

  private ongoingGetViews: string[] = []

  get moduleName() {
    return "CoreActions"
  }
  get dependencies() {
    return ["Store", "ModelManager", "ProgressManager", "ConversionMap", "DocumentConverter"]
  }

  async getDocument<T extends Doc>(api: CoreApi, parameters: RestApiCallParameters<T>): Promise<T | undefined> {
    this.logger.debug("Get document", parameters)

    if (!parameters.type) this.logger.errorAndThrow("Attempted to get document without type", parameters)

    parameters.apiCallType ||= "get"

    const progress = this.progressManager.startProgress({
      name: "getDocument",
      scope: `${parameters.type}_${parameters.id}`
    })

    const response = await api.restApiCall(parameters)
    if (parameters.rawData) {
      return await response.json()
    }

    if (parameters.skipLocalUpdates) {
      return undefined
    }

    if (response.ok) {
      const payload = await response.json()
      this.store.dispatch<ReceiveDocumentAction<T>>({
        type: "ReceiveDocument",
        payload,
        parameters,
        onReceiveAfter: this.getDocumentDuring
      })

      const document = this.modelManager.getDocument<T>(payload.id, parameters.type)
      if (document) {
        this.getDocumentAfter.forEach(handler => handler<T>(document, parameters))
      }

      return document
    }

    let json: Record<string, string>
    try {
      json = api.errorStatusOnly || parameters.errorStatusOnly ? {} : await response.json()
    } catch (e) {
      const { name, message } = e as Error
      json = { error: name, errorDetails: message }
    }
    const error = this.getHttpError(response?.status ?? 500, json)
    this.progressManager.failProgress(progress?.id, error)

    return Promise.reject(error)
  }

  async createDocument<T extends Doc>(
    api: CoreApi,
    params: Partial<RestApiCallParameters<Partial<T>>>
  ): Promise<T | undefined> {
    if (!params?.body) this.logger.errorAndThrow("Attempted to create document without body parameter", params)

    const documentType = params.type || (params.body as T).__type
    if (!documentType) this.logger.errorAndThrow("Attempted to create document without document type", params)

    const body = this.documentConverter.exportDocument<T>(params.body as T, documentType) as unknown as T

    const parameters: RestApiCallParameters<T> = {
      ...params,
      apiCallType: params.apiCallType || "create",
      type: documentType,
      body
    }

    const progress = this.progressManager.startProgress({
      name: "CreateDocument",
      scope: documentType
    })

    this.createDocumentBefore.forEach(handler => {
      if (!handler(parameters)) {
        this.logger.info("Create document call canceled by event handler")
      }
    })

    const response = await api.restApiCall(parameters)
    if (response.ok) {
      this.progressManager.completeProgress(progress?.id)

      if (parameters.skipLocalUpdates) {
        return undefined
      }

      const payload = await response.json()
      this.store.dispatch<ReceiveDocumentAction<T>>({
        type: "ReceiveDocument",
        payload,
        parameters
      })

      const document = this.getDocumentLocal<T>(payload.id, parameters.type)
      if (document) {
        this.createDocumentAfter.forEach(handler => handler<T>(document, parameters))
      }

      this.invalidateViewsIfNeeded(parameters.apiCallType!, documentType)

      return document
    }

    const json = api.errorStatusOnly || parameters.errorStatusOnly ? {} : await response.json()
    const error = this.getHttpError(response?.status ?? 500, json)
    this.progressManager.failProgress(progress?.id, error)

    return Promise.reject(error)
  }

  async updateDocument<T extends Doc>(
    api: CoreApi,
    document: T,
    parameters: Partial<RestApiCallParameters<T>>
  ): Promise<T | undefined> {
    parameters = parameters || {}
    this.logger.debug("Update document", { document, parameters })

    parameters.body ||= document
    if (!parameters.body) this.logger.errorAndThrow("Attempted to update document but no document provided")

    parameters.type ||= (parameters.body as T).__type
    if (!parameters.type) this.logger.errorAndThrow("Attempted to update document without type", parameters)

    parameters.id ||= document.id
    if (!parameters.id) this.logger.errorAndThrow("Attempted to update document without id", parameters)

    parameters.apiCallType ||= "update"

    const progress = this.progressManager.startProgress({
      name: "UpdateDocument",
      scope: `${parameters.type}_${document.id}`
    })

    const response = await api.restApiCall(parameters)
    if (response.ok) {
      this.progressManager.completeProgress(progress?.id)

      if (parameters.skipLocalUpdates) {
        return undefined
      }

      const payload = await response.json()
      this.store.dispatch<ReceiveDocumentAction<T>>({
        type: "ReceiveDocument",
        payload,
        parameters
      })

      this.invalidateViewsIfNeeded(parameters.apiCallType, parameters.type)

      const localDocument = this.getDocumentLocal<T>(payload.id, parameters.type)
      if (localDocument) {
        this.updateDocumentAfter.forEach(handler => handler<T>(localDocument, document, parameters))
      }

      return localDocument
    }

    this.progressManager.failProgress(progress?.id)

    return Promise.reject(response)
  }

  async removeDocument(api: CoreApi, parameters: Partial<RestApiCallParameters>): Promise<Response | undefined> {
    if (!parameters.id) this.logger.errorAndThrow("Attempted to remove document without id parameter", parameters)
    if (!parameters.type) this.logger.errorAndThrow("Attempted to remove document without type parameter", parameters)

    parameters.apiCallType = "remove"

    const progress = this.progressManager.startProgress({
      name: "RemoveDocument",
      scope: `${parameters.type}_${parameters.id}`
    })

    const response = await api.restApiCall(parameters)
    if (response.ok) {
      this.progressManager.completeProgress(progress?.id)

      if (parameters.skipLocalUpdates) {
        return
      }

      const document = this.getDocumentLocal(parameters.id as string, parameters.type!)
      if (document) {
        this.removeDocumentLocal(document)
        this.removeDocumentAfter.forEach(handler => handler(document, parameters as RestApiCallParameters))
      }

      this.invalidateViewsIfNeeded(parameters.apiCallType, parameters.type!)

      return response
    }

    this.progressManager.failProgress(progress?.id)

    return Promise.reject(response)
  }

  getDocumentLocal<T extends Doc>(id: string, type: string): T | undefined {
    return this.modelManager.getDocument<T>(id, type)
  }

  getAllDocumentsLocal<T extends Doc>(type: string): T[] {
    return this.modelManager.getDocuments<T>(type)
  }

  setDocumentLocal<T extends Doc>(document: T) {
    this.store.dispatch<SetDocumentLocalAction<T>>({ type: "SetDocumentLocal", document })
  }

  setDocumentsLocal<T extends Doc>(documents: T[]) {
    this.store.dispatch<SetDocumentsLocalAction<T>>({ type: "SetDocumentsLocal", documents })
  }

  getDefaultDocumentLocal<T extends Doc>(type: string): T | undefined {
    return this.modelManager.getDefaultDocument(type)
  }

  setDefaultDocumentLocal<T extends Doc>(document: OptionalKeys<T, "id">) {
    this.logger.debug("Set default document local", document)

    if (document.id && document.id !== defaultDocumentId)
      (document as T & { __originalId: T["id"] }).__originalId = document.id

    document.id = defaultDocumentId

    this.store.dispatch<SetDocumentLocalAction<T>>({ type: "SetDocumentLocal", document: document as T })
  }

  removeDocumentLocal<T extends Doc>(doc: T) {
    this.logger.debug("Remove document", doc)

    this.store.dispatch({ type: "RemoveDocumentLocal", payload: doc })

    this.deleteCascade(doc)
  }

  getViewKey(parameters: ViewParameters) {
    return JSON.stringify(parameters)
  }

  clearViewFromOngoing(viewKey: string) {
    this.ongoingGetViews = this.ongoingGetViews.filter(v => v !== viewKey)
  }

  getViewLocal<T extends Doc>(viewName: string) {
    const view = this.modelManager.getView<T>(viewName)

    if (view && !view.valid) {
      this.logger.info("View was marked as invalid. Refresing it", { viewName, parameters: view.parameters })

      const viewKey = this.getViewKey(view.parameters)

      // Don't get views that are already on progress
      if (!this.ongoingGetViews.includes(viewKey)) {
        this.getView(view.api!, view.parameters)
      }
    }

    return view
  }

  async getView<T extends Doc>(api: CoreApi, parameters: ViewParameters): Promise<ViewContainerManager<T>> {
    this.logger.debug("Get view", parameters)

    const viewKey = this.getViewKey(parameters)
    this.ongoingGetViews.push(viewKey)

    // Check if we are fetching all documents
    const conversionMapItem = parameters.type ? this.conversionMap.map[parameters.type] : undefined
    const fetchAll = conversionMapItem?.getAll === true

    parameters.apiCallType = "get"

    const progress = this.progressManager.startProgress({ name: "RequestView", scope: parameters.type })

    const response = await api.restApiCall(parameters)
    if (response.ok) {
      this.progressManager.completeProgress(progress?.id)

      // No content. Dispatch empty message to clear view
      if (response.status === 204) {
        this.store.dispatch<ReceiveViewAction>({
          type: "ReceiveView",
          parameters,
          api
        })

        return this.modelManager.getView<T>(parameters.type!)
      }

      const json = await response.json()
      const items = json.items || []
      if (fetchAll) {
        let page = 1
        let currentTotal = items.length
        const total = json.total

        // If current page is not enough, fetch more and merge results
        if (total > currentTotal) {
          // Get the default per_page param for this collection
          const apiDescription = conversionMapItem.__api
          const perPageParam = apiDescription?.get?.search?.filter(param => param.name == "per_page")
          const perPage = perPageParam?.[0]?.default || 500

          // Get new results until we hit the end of the list
          while (total > currentTotal) {
            page++
            const nextParameters = {
              ...parameters,
              page,
              per_page: perPage
            }
            const results = await api.restApiCall(nextParameters)
            const nextJson = await results.json()
            const nextItems = nextJson.items
            items.push(...nextItems)
            currentTotal += nextItems.length
          }
        }
      }

      this.store.dispatch<ReceiveViewAction>({
        type: "ReceiveView",
        payload: parameters.itemsKey ? json[parameters.itemsKey] : items || json,
        parameters,
        raw: json,
        api
      })

      this.clearViewFromOngoing(viewKey)

      return this.modelManager.getView(parameters.type!)
    }

    const json = await response.json()
    this.progressManager.failProgress(progress?.id, json.getHttpError)

    return Promise.reject(response)
  }

  public invalidateView(viewName: string, invalidationType: "unload" | "invalidateWhenUsed") {
    this.logger.debug("Invalidating view", { viewName, invalidationType })

    this.store.dispatch<InvalidateViewAction>({
      type: "InvalidateView",
      viewName,
      invalidationType
    })
  }

  public invalidateAllViews(invalidationType: "unload" | "invalidateWhenUsed") {
    this.logger.debug("Invalidating all views", invalidationType)

    this.store.dispatch<InvalidateAllViewsAction>({
      type: "InvalidateAllViews",
      invalidationType
    })
  }

  importDocument<T extends Doc>(documentRaw: T, documentType: string): T | undefined {
    if (!documentRaw) return

    this.store.dispatch<ReceiveDocumentAction<T>>({
      type: "ReceiveDocument",
      payload: documentRaw,
      parameters: { type: documentType }
    })

    return this.getDocumentLocal<T>(documentRaw.id, documentType)
  }

  protected invalidateViewsIfNeeded(apiCallType: string, documentType: string) {
    this.logger.debug("Updating related views after REST API operation", { apiCallType, documentType })

    for (const key of Object.keys(this.conversionMap.map)) {
      const collection = this.conversionMap.map[key]

      // Check if this view needs to be updated due to update
      if (collection?.__api?.invalidateUnload?.[documentType]?.[apiCallType]) {
        this.logger.debug("Unloading view because of another action", { key, apiCallType, documentType })

        this.invalidateView(key, "unload")
      } else if (collection?.__api?.invalidateRefreshWhenUsed?.[documentType]?.[apiCallType]) {
        this.logger.debug("Invalidating view because of another action. It will be refreshed when accessed next", {
          key,
          apiCallType,
          documentType
        })

        // Check if invalidation requires delay, with some actions the server might not have updated the index list
        // right after update finishes Delay comes from ES index
        if (collection.__api.invalidateRefreshWhenUsedDelay) {
          setTimeout(
            () => this.invalidateView(key, "invalidateWhenUsed"),
            collection.__api.invalidateRefreshWhenUsedDelay
          )
        } else {
          this.invalidateView(key, "invalidateWhenUsed")
        }
      }
    }
  }

  protected getHttpError(status: number, json: any): HttpError {
    return {
      error: json.error,
      httpStatusCode: status,
      error_description: json.errorDetails ?? json.error_description
    }
  }

  protected deleteCascade<T extends Doc>(doc: T) {
    if (this.conversionMap.map[doc.__type].__noDeleteCascade) return

    for (const docType of Object.keys(this.conversionMap.map)) {
      const conversionMapItem = this.conversionMap.map[docType]

      for (const linkName of Object.keys(conversionMapItem)) {
        const converter = conversionMapItem[linkName] as DocumentValueConverter
        if (converter.converterType === "LinkConverter" && converter.target === doc.__type) {
          // Found a single link. Delete all references
          for (const docToCheck of this.getAllDocumentsLocal(docType)) {
            if (!docToCheck || !docToCheck[linkName]) continue

            if ((docToCheck as T & Record<typeof linkName, { id: string }>)[linkName].id === doc.id) {
              this.logger.debug("Delete cascade, removing reference to deleted object from document", docToCheck)

              this.setDocumentLocal({
                ...docToCheck,
                [linkName]: undefined
              })
            }
          }
        }

        if (converter.converterType === "LinkArrayConverter" && converter.target === doc.__type) {
          // Found array link. Delete all references
          for (const docToCheck of this.getAllDocumentsLocal(docType)) {
            if (!docToCheck || !docToCheck[linkName]) continue

            const oldLinks = (docToCheck as T & Record<typeof linkName, { id: string }[]>)[linkName]
            const newLinks = oldLinks.filter(link => link.id !== doc.id)
            if (oldLinks.length !== newLinks.length) {
              const newDoc = {
                ...docToCheck,
                [linkName]: newLinks
              }

              this.logger.debug(
                "Delete cascade, removing reference to deleted object from document's link array",
                newDoc
              )
              this.setDocumentLocal(newDoc)
            }
          }
        }
      }
    }
  }
}

// Actions

export interface Action {
  type: string
}

export interface RequestViewAction extends Action {
  type: "RequestView"
}

export interface ReceiveViewAction extends Action {
  type: "ReceiveView"
  documentType?: string
  payload?: any
  itemsKey?: string
  totalKey?: string
  page?: number
  itemsPerPage?: number
  parameters: ViewParameters
  raw?: any
  api: CoreApi
}

export interface ReceiveDocumentAction<T extends Doc> extends Action {
  type: "ReceiveDocument"
  payload: T
  parameters: Partial<RestApiCallParameters<T>>
  inline?: boolean
  onReceiveAfter?: ((document: T, parameters: Partial<RestApiCallParameters<T>>) => T)[]
}

export interface InvalidateViewAction extends Action {
  type: "InvalidateView"
  viewName: string
  documentType?: string
  invalidationType: "unload" | "invalidateWhenUsed"
}

export interface InvalidateAllViewsAction extends Action {
  type: "InvalidateAllViews"
  documentType?: string
  invalidationType: "unload" | "invalidateWhenUsed"
}

export interface SetDocumentLocalAction<T extends Doc = Doc> extends Action {
  type: "SetDocumentLocal"
  document: T
}

export interface SetDocumentsLocalAction<T extends Doc = Doc> extends Action {
  type: "SetDocumentsLocal"
  documents: T[]
}
