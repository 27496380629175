import type { ViewParameters } from "core/modules/actions/CoreActions"
import { CoreApi } from "core/modules/api/CoreApi"

import { Logger } from "../../../modules/logging/Logger"
import { ContainerManager } from "./ContainerManager"
import { Doc, Link, ViewContainer } from "./Model"
import { ModelManagerInternal } from "./ModelManager"

export interface View<T extends Doc> {
  documents: T[]
  parameters: ViewParameters
  raw: any
  valid: boolean
  loaded: boolean
}

export class ViewContainerManager<T extends Doc> extends ContainerManager implements View<T> {
  private _valid = true

  api?: CoreApi

  constructor(
    private container: ViewContainer | undefined,
    protected modelManager: ModelManagerInternal,
    protected logger: Logger
  ) {
    super()

    if (!container) return // This is a dummy container with no contents

    if (!container.documentType) throw new Error("View container missing document type")

    if (!container.array) {
      this.logger.debug("Container didn't have an array. Creating it.")
      container.array = []
    }
  }

  get documents() {
    if (!this.container) return []

    const documents: T[] = []

    // Make sure all documents are present. Don't return removed documents
    for (const link of this.container.array) {
      const document = this.modelManager.getDocument<T>(link.id, link.__type)
      if (document) documents.push(document)
    }

    return documents
  }

  get parameters(): any {
    return this.container?.parameters ?? {}
  }

  set parameters(parameters: any) {
    if (!this.container) return

    this.container.parameters = parameters
  }

  get raw(): any {
    if (!this.container) return {}

    return this.container.raw ? this.container.raw : {}
  }

  set raw(raw: any) {
    if (!this.container) return

    this.container.raw = raw
  }

  get valid() {
    return this._valid
  }

  get loaded() {
    return !!this.container
  }

  /**
   * Append link to a given document to the view's end
   */
  push(document: T): void {
    if (!this.container) return

    this.verifyStateModify()
    this.setDirtyFlag()

    const link: Link<T> = {
      __type: this.container.documentType,
      id: document.id
    }

    this.container.array.push(link)
  }

  initialize() {
    this.verifyStateModify()
    if (this.container) this.container.array = []
    this._dirty = false
    this._valid = true
  }

  markAsInvalid() {
    this._valid = false
  }

  /**
   * In case of any mutation, the unerlying data structures will be cloned and dirty flag set. We do not wish to
   * clone always due to performance cost.
   */
  private setDirtyFlag() {
    if (this.dirty || !this.container) return

    this.logger.trace("Dirty flag set. Cloning view container", this.container.viewName)

    this.container = this.modelManager.cloneViewContainer(this.container.viewName)
    this.container.array = [...this.container.array]
    this._dirty = true
  }
}
