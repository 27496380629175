import { surveysApiConfig } from "app/surveys/modules/config/surveysApiConfig"
import { CoreApi, CoreApiModule, RestApiCallParameters } from "core/modules/api/CoreApi"
import type { ApiMethodDescription } from "core/modules/state/conversionmap/ConversionMap"

import { appName } from "../../AppName"

export interface SurveysApi extends CoreApi {}

export class SurveysApiModule extends CoreApiModule implements SurveysApi {
  protected accessTokenScope = "survey_api"
  protected apiConfig = surveysApiConfig

  errorStatusOnly = false

  get moduleName() {
    return "SurveysApi"
  }

  get appName() {
    return appName
  }

  restApiCall(parameters: RestApiCallParameters): Promise<Response> {
    if (this.accessToken) {
      // Authenticated request
      parameters.access_token = this.accessToken.access_token
    } else {
      // Unauthenticated requests
      parameters.client_id = this.apiConfig.clientId
      parameters.client_secret = this.apiConfig.clientSecret
    }

    return super.restApiCall(parameters)
  }

  protected getSegments(methodDescription: ApiMethodDescription, parameters: RestApiCallParameters): string[] {
    return [...this.apiConfig.apiRelativePath, ...super.getSegments(methodDescription, parameters)]
  }
}
