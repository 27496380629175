import MuiDialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import React, { PropsWithChildren } from "react"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import Typography from "lib/ui/components/typography/Typography"

import { ViewComponent } from "../base/ViewComponent"
import Button from "../form/Button"

const styles = ({ palette }: Theme) =>
  createStyles({
    showOnTop: {
      height: "auto"
    },
    paddingNone: {
      padding: 0
    },
    paddingSmall: {
      padding: "0.5rem"
    },
    paddingMedium: {
      padding: "1.5rem"
    },
    paddingLarge: {
      padding: "3rem"
    },
    paddingWide: {
      padding: "2.75rem 6.25rem"
    },
    xs: {
      width: "30rem",
      maxWidth: "30rem"
    },
    actionPaddingSmall: {
      padding: "0.5rem"
    },
    actionPaddingMedium: {
      padding: "0.5rem 1.5rem"
    },
    actionPaddingLarge: {
      padding: "0.5rem 3rem"
    },
    actionPaddingWide: {
      padding: "0.5rem 6.25rem 2.75rem 0.5rem"
    },
    small: {
      width: "45rem",
      maxWidth: "45rem"
    },
    medium: {
      width: "55rem",
      maxWidth: "55rem"
    },
    large: {
      width: "65rem",
      maxWidth: "65rem"
    },
    xl: {
      width: "75rem",
      maxWidth: "75rem"
    },
    header: {
      marginBottom: "0.25rem !important"
    },
    headerBackground: {
      background: Dialog.theme.colors.dialogHeaderBackgroundColor.toString(),
      color: palette.primary.contrastText,
      minHeight: "5rem",
      padding: "1rem 1.5rem 1rem 1.5rem"
    },
    actionArea: {
      backgroundColor: Dialog.theme.colors.faintColor.toString(),
      margin: 0,
      height: "4rem",
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem"
    },
    buttonsRight: {
      display: "flex",
      justifyContent: "flex-end"
    },
    noTransitions: {
      transition: "none !important",

      "&>*:first-child": {
        transition: "none !important"
      }
    },
    transparentBackdrop: {
      background: "transparent"
    }
  })

export interface DialogProps extends PropsWithChildren<ViewComponentProps> {
  open?: boolean
  size?: "xs" | "small" | "medium" | "large" | "xl"
  padding?: "none" | "small" | "medium" | "large" | "wide"
  actionPadding?: "small" | "medium" | "large" | "wide"
  margin?: "top" | "bottom"
  noTransitions?: boolean
  showOnTop?: boolean
  transparentBackdrop?: boolean
  className?: string
  paperClass?: string
  title?: string
  renderHeader?
  headerBackground?: boolean
  okButtonText?: string
  okDisabled?: boolean
  previousDisabled?: boolean
  previousButtonText?: string
  cancelButtonText?: string
  buttons?: "ok" | "okCancel" | "nextPrevCancel" | "custom"
  renderActions?: () => JSX.Element
  onOk?: () => void
  onCancel?: () => void
  onPrevious?: () => void
  onClose?: () => void
}

class Dialog extends ViewComponent<DialogProps & WithStyles<typeof styles>> {
  public get componentName(): string[] {
    return ["lib", "ui", "dialogs", "Dialog"]
  }

  render() {
    const { open, onClose, noTransitions, showOnTop, transparentBackdrop, classes, paperClass } = this.props

    return (
      <MuiDialog
        open={open !== false}
        BackdropProps={
          transparentBackdrop
            ? {
                classes: {
                  root: classes.transparentBackdrop
                }
              }
            : undefined
        }
        classes={{
          paper: classNames(paperClass, this.getSizeClass()),
          container: classNames(noTransitions && classes.noTransitions, showOnTop && classes.showOnTop),
          root: classNames(noTransitions && classes.noTransitions)
        }}
        onClose={onClose}
      >
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderActions()}
      </MuiDialog>
    )
  }

  private renderContent() {
    return <DialogContent className={this.getPaddingClass()}>{this.props.children}</DialogContent>
  }

  private renderHeader() {
    const { classes, renderHeader, title, headerBackground } = this.props

    if (!renderHeader && !title) return

    return (
      <DialogTitle disableTypography className={headerBackground !== false ? classes.headerBackground : undefined}>
        {title && (
          <Typography variant="smallHeading" useContrastColor={headerBackground !== false}>
            {title}
          </Typography>
        )}
        {renderHeader && renderHeader()}
      </DialogTitle>
    )
  }

  private renderActions() {
    const { renderActions, classes, buttons } = this.props

    if (!renderActions && (!buttons || buttons === "custom")) return

    return (
      <DialogActions className={this.getActionPaddingClass()} classes={{ root: classes.actionArea }} disableSpacing>
        {buttons === "custom" || !buttons ? renderActions?.() : this.renderDefaultButtons()}
      </DialogActions>
    )
  }

  private renderDefaultButtons() {
    const { classes, buttons, okButtonText, cancelButtonText, okDisabled, previousDisabled, previousButtonText } =
      this.props

    if (buttons === "ok") {
      return (
        <Grid container>
          <Grid item xs={12} className={classes.buttonsRight}>
            <Button margin onClick={this.onOk} disabled={okDisabled}>
              {okButtonText || this.txt("core", "components", "ui", "form", "ok")}
            </Button>
          </Grid>
        </Grid>
      )
    } else if (buttons === "okCancel") {
      return (
        <Grid container>
          <Grid item xs={12} className={classes.buttonsRight}>
            <Button margin variant="secondary" onClick={this.onCancel}>
              {cancelButtonText || this.txt("core", "components", "ui", "form", "cancel")}
            </Button>

            <Button margin onClick={this.onOk} disabled={okDisabled}>
              {okButtonText || this.txt("core", "components", "ui", "form", "ok")}
            </Button>
          </Grid>
        </Grid>
      )
    } else if (buttons === "nextPrevCancel") {
      return (
        <Grid container>
          <Grid item xs={4}>
            <Button margin disabled={previousDisabled} variant="secondary" onClick={this.onPrevious}>
              {previousButtonText || this.txt("core", "components", "ui", "form", "previous")}
            </Button>
          </Grid>
          <Grid item xs={8} className={classes.buttonsRight}>
            <Button margin variant="secondary" onClick={this.onCancel}>
              {cancelButtonText || this.txt("core", "components", "ui", "form", "cancel")}
            </Button>

            <Button margin onClick={this.onOk} disabled={okDisabled}>
              {okButtonText || this.txt("core", "components", "ui", "form", "next")}
            </Button>
          </Grid>
        </Grid>
      )
    }
  }

  private getSizeClass() {
    const { classes, size } = this.props

    return size && ["xs", "small", "large", "xl"].includes(size) ? classes[size] : classes.medium
  }

  private getPaddingClass() {
    const { classes, padding } = this.props
    switch (padding) {
      case "none":
        return classes.paddingNone
      case "small":
        return classes.paddingSmall
      case "large":
        return classes.paddingLarge
      case "medium":
        return classes.paddingMedium
      case "wide":
      default:
        return classes.paddingWide
    }
  }

  private getActionPaddingClass() {
    const { classes, actionPadding } = this.props
    switch (actionPadding) {
      case "small":
        return classes.actionPaddingSmall
      case "medium":
        return classes.actionPaddingMedium
      case "large":
        return classes.actionPaddingLarge
      case "wide":
        return classes.actionPaddingWide
      default:
        return undefined
    }
  }

  private onOk = () => this.props.onOk?.()

  private onCancel = () => this.props.onCancel?.()

  private onPrevious = () => this.props.onPrevious?.()
}

export default withStyles(styles)(Dialog)
