import type { ReactNode } from "react"

import type { AppState } from "app/insights_admin/modules/state/model/Model"
import { BaseComponent, BaseComponentProps, BaseComponentState } from "core/components/base/BaseComponent"
import type { CoreActions } from "core/modules/actions/CoreActions"
import type { Doc, Link } from "core/modules/state/model/Model"
import type { ModelManager } from "core/modules/state/model/ModelManager"

export interface LogicComponentProps extends BaseComponentProps {}

export interface LogicComponentState extends BaseComponentState {}

export abstract class LogicComponent<
  P extends LogicComponentProps,
  S extends LogicComponentState | void = {}
> extends BaseComponent<P, S> {
  // biome-ignore lint/suspicious/noExplicitAny: Apps are allowed to have any configuration
  protected appConfig: Record<string, any> = {}

  get defaultDependencies() {
    return [...super.defaultDependencies, "ModelManager", "CoreActions"]
  }

  declare coreActions: CoreActions
  declare modelManager: ModelManager

  private _id?: string
  protected get id(): string | undefined {
    return this._id
  }

  render(): ReactNode {
    // Todo: move to another method
    if (!this._id) this._id = this.modelManager.getUniqueId()

    return undefined
  }

  protected doc<T extends Doc>(link: Link<T>): T | undefined
  protected doc<T extends Doc>(id: string | number | undefined, type: string): T | undefined
  protected doc<T extends Doc>(idOrLink: string | number | undefined | Link<T>, type?: string): T | undefined {
    if (!idOrLink) return undefined

    if (typeof idOrLink === "string" || typeof idOrLink === "number") {
      return this.coreActions.getDocumentLocal<T>(idOrLink, type)
    }

    return this.coreActions.getDocumentLocal<T>(idOrLink.id, idOrLink.__type)
  }

  protected docWithLink<T extends Doc>(link: Link<T>): T {
    return { ...link, ...this.doc<T>(link) } as T
  }

  protected docAll<T extends Doc>(type: string) {
    return this.coreActions.getAllDocumentsLocal<T>(type)
  }

  protected docDefault<T extends Doc>(type: string) {
    return this.coreActions.getDefaultDocumentLocal<T>(type)
  }

  protected view<T extends Doc>(name: string) {
    return this.coreActions.getViewLocal<T>(name)
  }

  protected set<T extends Doc>(document: T) {
    this.coreActions.setDocumentLocal<T>(document)
  }

  updateState(update: (state: S) => void, callback?: () => void): void {
    this.setState(prevState => {
      const newState = Object.assign({}, prevState)
      update(newState)
      return newState
    }, callback)
  }

  protected updateDefault<T extends Doc>(type: string, update: (defaultDocument: T) => void): void {
    const doc = Object.assign({}, this.docDefault<T>(type))
    update(doc)
    this.setDefault(doc, type)
  }

  protected setDefault<T extends Doc>(document: T, type?: string) {
    if (type) document.__type = type
    this.coreActions.setDefaultDocumentLocal(document)
  }

  protected showBlocker(loading: boolean) {
    this.updateDefault<AppState>("AppState", appState => (appState.blocked = loading))
  }
}
