import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import QRCode from "react-qr-code"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import {
  ReportVariant,
  reportLargeBodyClass,
  reportLargeHeadingClass,
  reportSubHeadingClass
} from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  reportVariant: ReportVariant
  isInitialReport: boolean
}

export default class ReportSectionCongratulations extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionCongratulations"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.variant)
  }

  render() {
    const { reportVariant, isInitialReport } = this.props

    return (
      <Grid container className={classNames(styles.container, "same-page")}>
        {reportVariant === "pdf" && <Grid spacer spacerSize={16} />}
        <Grid sm={12}>
          <Typography
            component="h1"
            useContrastColor
            className={classNames(reportLargeHeadingClass, styles.congratulationsClass)}
          >
            {isInitialReport ? this.txt("congratulations_title") : this.txt("congratulations_comparison_title")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography useContrastColor className={reportLargeBodyClass}>
            {isInitialReport ? this.txt("congratulations_body") : this.txt("congratulations_comparison_body")}
          </Typography>
        </Grid>
        {reportVariant === "pdf" && (
          <>
            <Grid spacer spacerSize={16} />
            <Grid xs={12} className={styles.qrCodeOuterContainer}>
              <div className={styles.qrDescription}>
                <a href={window.location.href}>
                  <Typography useContrastColor className={reportSubHeadingClass}>
                    {this.txt("qr_code")}
                  </Typography>
                </a>
              </div>
              <div className={styles.qrCodeInnerContainer}>
                <QRCode className={styles.qrCode} value={window.location.href} />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    )
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  styles = stylesheet({
    container: {
      marginBottom: "2rem"
    },
    congratulations: {
      // Allow title to go a little bit outside normal bounds for visual reasons
      marginRight: reportVariant === "mobile" ? 0 : "-4rem"
    },
    qrCodeInnerContainer: {
      display: "flex",
      justifyContent: "center",
      border: "2px solid",
      borderRadius: "1rem",
      padding: "0.75rem 0.75rem"
    },
    qrCode: {
      width: "10rem",
      height: "10rem"
    },
    qrDescription: {
      marginTop: "1rem",
      textAlign: "center"
    },
    qrCodeOuterContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "0.25rem 0.75rem"
    }
  })
}
