import { createStyles, withStyles } from "@material-ui/core"
import type { WithStyles } from "@material-ui/core/styles"

import { QuestionOption } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"

import { ViewComponent } from "../../base/ViewComponent"

interface FieldQuestionViewProps extends ViewComponentProps, WithStyles<typeof styles> {
  notes?: Map<string, string | undefined>
  options: QuestionOption[]
  onChange: (notes: Map<string, string>) => void
}

interface FieldQuestionViewState extends ViewComponentState {
  notes: Map<string, string>
}

const styles = () =>
  createStyles({
    openQuestionDesc: {
      marginTop: "1rem",
      marginBottom: "2rem",
      color: "#89949D"
    },
    note: {
      width: "100%"
    }
  })

class FieldQuestionView extends ViewComponent<FieldQuestionViewProps, FieldQuestionViewState> {
  constructor(props: FieldQuestionViewProps) {
    super(props)

    this.state = { notes: props.notes ?? new Map() }
  }

  get componentName() {
    return ["survey", "ui", "questions", "FieldQuestionView"]
  }

  private onChange(optionId: string, note: string) {
    this.updateState(state => state.notes.set(optionId, note))
  }

  render() {
    super.render()

    const { classes, options, onChange } = this.props

    const textareas = options.map(option => (
      <div key={option.id!.toString()}>
        {options.length > 1 && <p>{this.txt(option.title)}</p>}
        <textarea
          maxLength={3000}
          placeholder={this.txt("open_question_placeholder")}
          name="note"
          value={this.state.notes.get(option.id!.toString())}
          className={classes.note}
          onChange={event => this.onChange(option.id!.toString(), event.target.value)}
          onBlur={event => onChange(this.state.notes)}
        />
      </div>
    ))

    return <div>{textareas}</div>
  }
}

export default withStyles(styles)(FieldQuestionView)
