import { DocumentValueConverterImpl } from "core/modules/state/conversionmap/ValueConverters"

import { Doc } from "../model/Model"

export class QuestionCategoryConverter extends DocumentValueConverterImpl {
  import(document: Doc, key: string) {
    if (!document) return undefined

    // Put questions without category into an empty category
    document[key] = document[key] ?? { en: "" }
  }
}
