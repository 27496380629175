import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as React from "react"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../base/ViewComponent"
import Typography from "../typography/Typography"
import Equals from "./Equals"
import Triangle from "./Triangle"

interface ComparisonProps extends ViewComponentProps {
  score: number | undefined
  comparisonScore: number | undefined
  top?: string
  left?: string
  transform?: string
  className?: string
  onClick?: (event: React.SyntheticEvent<any>) => void
}

export default class Comparison extends ViewComponent<ComparisonProps, {}> {
  constructor(props: ComparisonProps) {
    super(props)

    setupStyles(props)
  }

  render() {
    super.render()

    const { score, comparisonScore, className, top, left, transform, onClick } = this.props

    if (score === undefined || comparisonScore === undefined) return null

    return (
      <div
        style={{ top, left, position: top !== undefined && left !== undefined ? "absolute" : undefined, transform }}
        className={classNames(
          styles.container,
          score - comparisonScore < -0.5 && styles.majorDecrease,
          score - comparisonScore > 0.5 && styles.majorIncrease,
          className
        )}
        onClick={onClick}
      >
        {score !== comparisonScore && (
          <Triangle
            direction={score > comparisonScore ? "up" : "down"}
            color={score > comparisonScore ? this.theme.colors.report.positive : this.theme.colors.report.negative}
            width={0.6}
            height={0.6}
          />
        )}
        {score === comparisonScore && <Equals color={this.theme.colors.report.neutral} width={0.75} height={0.75} />}
        <Typography className={styles.typography}>{(score - comparisonScore)?.toFixed(1)}</Typography>
      </div>
    )
  }
}

let styles

const setupStyles = (props: ComparisonProps) => {
  styles = stylesheet({
    container: {
      width: "3rem",
      height: "1.5rem",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "0.25rem",
      backgroundColor: Comparison.theme.colors.hintsa.alabaster.toString(),
      borderRadius: "0.75rem"
    },
    typography: {
      margin: 0,
      fontSize: "14px",

      fontWeight: 600
    },
    majorDecrease: {
      // Individual color for clear decrease in score
      backgroundColor: "#FEAD9A"
    },
    majorIncrease: {
      // Individual color for clear increase in score
      backgroundColor: "#B3E6FF"
    }
  })
}
