import { createStyles, withStyles } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { WithStyles } from "@material-ui/core/styles"
import { ColorHelper } from "csx"
import { type ReactNode } from "react"

import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../base/ViewComponent"

export interface CardLayoutProps extends ViewComponentProps {
  show?: boolean
  header?: ReactNode
  headerBackgroundColor?: ColorHelper
  backgroundHeight?: number | string
  backgroundImage?: string
  background?: string
  padding?: boolean
  maxWidth?: number | string
  minWidth?: number | string
  width?: number | string
  margin?: number | string
}

class CardLayout extends ViewComponent<CardLayoutProps & WithStyles<typeof styles>> {
  render() {
    const { children, classes, header, show } = this.props

    if (show === false) return null

    return (
      <Card className={classes.root} elevation={6}>
        <div className={classes.background} />
        <CardContent>
          {header}
          {children}
        </CardContent>
      </Card>
    )
  }
}

const styles = () =>
  createStyles<any, CardLayoutProps>({
    root: props => ({
      margin: props.margin || "100px auto",
      maxWidth: props.maxWidth,
      minWidth: props.minWidth,
      width: props.width,
      borderRadius: "1rem"
    }),

    background: props => ({
      backgroundImage: props.backgroundImage,
      background: props.background,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: props.backgroundHeight ?? "100%",
      left: 0,
      position: props.backgroundHeight ? "absolute" : "fixed",
      right: 0,
      top: 0,
      zIndex: -1000
    }),

    content: {
      display: "flex",
      flexDirection: "column"
    }
  })

export default withStyles(styles)(CardLayout)
