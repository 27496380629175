import { PlannedSurvey, Survey } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"
import type { RequireKeys } from "lib/utils/TypeUtils"

import { LogicComponent } from "../../../base/LogicComponent"
import SelectSurveyPageView from "./SelectSurveyPageView"

interface SelectSurveyPageProps extends LogicComponentProps {
  language?: string
  onLogout: () => void
  onSelectSurvey: (plannedSurveyId: string) => void
  onSetLanguage: (language: string) => void
  plannedSurveys: PlannedSurvey[]
}

export default class SelectSurveyPage extends LogicComponent<SelectSurveyPageProps> {
  render() {
    super.render()

    const plannedSurveys = this.props.plannedSurveys.map<PlannedSurvey>(plannedSurvey => ({
      ...plannedSurvey,
      survey: this.doc<RequireKeys<Survey, "id" | "__type">>(plannedSurvey.survey.id, "Survey")!
    }))

    return (
      <SelectSurveyPageView
        logo={`${this.appConfig.imagesRoot}//hintsa/logo_hintsa_black.svg`}
        language={this.props.language}
        scheduledSurveys={plannedSurveys}
        onLogout={this.props.onLogout}
        onSetLanguage={this.props.onSetLanguage}
        onSelectSurvey={this.props.onSelectSurvey}
      />
    )
  }
}
