import { appConfig } from "app/surveys_app/modules/config/appConfig"
import { style } from "typestyle"

import {
  CoreThemeColors,
  CoreThemeFont,
  CoreThemeImages,
  ThemeModule as CoreThemeModule
} from "core/modules/themes/Theme"

// @ts-ignore  Not trying to fix theme's types..
export class ThemeModule extends CoreThemeModule {
  get moduleName() {
    return "Theme"
  }

  setup() {
    this._colors = new ThemeColors(this.themeConstants)
    this._font = new ThemeFont()
    this._images = new ThemeImages(appConfig.imagesRoot)
  }
}

export class ThemeColors extends CoreThemeColors {}

export class ThemeFont extends CoreThemeFont {}

export class ThemeImages extends CoreThemeImages {
  constructor(private imagePath: string) {
    super()
  }

  get header() {
    return `${this.imagePath}/hintsa/logo_hintsa_black.svg`
  }

  get headerClass(): string {
    return style({ backgroundPosition: "50px center" })
  }
  get title(): string {
    return ""
  }
  get background() {
    return ""
  }
}
