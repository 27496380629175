import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import type { ReactNode } from "react"

import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../../../base/ViewComponent"

const styles = () =>
  createStyles({
    container: {
      display: "flex"
    },
    progressIndicator: {
      borderRadius: "50%",
      backgroundColor: QuestionsProgress.theme.colors.windowColor.toString(),
      borderColor: QuestionsProgress.theme.colors.baseGray.toString(),
      borderWidth: "1px",
      borderStyle: "solid",
      width: "0.5rem",
      height: "0.5rem",
      margin: "0.25rem"
    },
    completed: {
      backgroundColor: QuestionsProgress.theme.colors.secondary.toString(),
      borderColor: QuestionsProgress.theme.colors.secondary.toString()
    }
  })

interface QuestionsProgressProps extends ViewComponentProps {
  maxItems: number
  progress: number
}

export class QuestionsProgress extends ViewComponent<QuestionsProgressProps & WithStyles<typeof styles>> {
  get componentName() {
    return ["survey", "pages", "questions", "QuestionsProgress"]
  }

  render() {
    const { classes } = this.props

    super.render()

    return <div className={classes.container}>{this.renderAllProgressindicators()}</div>
  }

  private renderAllProgressindicators() {
    const { progress, maxItems } = this.props

    const progressIndicators: ReactNode[] = []

    for (let i = 1; i <= maxItems; i++) {
      progressIndicators.push(this.renderProgressIndicator(i <= progress, i.toString()))
    }

    return progressIndicators
  }

  private renderProgressIndicator(completed: boolean, key: string): ReactNode {
    const { classes } = this.props

    return (
      <div key={key} className={classNames(classes.progressIndicator, completed && classes.completed)} title={key} />
    )
  }
}

export default withStyles(styles)(QuestionsProgress)
