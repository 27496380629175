import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import * as React from "react"
import { style } from "typestyle"
import { ViewComponent } from "../base/ViewComponent"

export type TriangleDirection = "up" | "right" | "down" | "left"

interface TriangleProps extends ViewComponentProps {
  direction: TriangleDirection
  width: number
  height: number
  color: csx.ColorHelper | string
  className?: string
  onClick?: (event: React.SyntheticEvent<any>) => void
}

export default class Triangle extends ViewComponent<TriangleProps, {}> {
  triangleClass
  leftClass
  rightClass
  upClass
  downClass

  constructor(props: TriangleProps) {
    super(props)
    this.setupStyles()
  }

  render() {
    super.render()

    let directionClass
    switch (this.props.direction) {
      case "up":
        directionClass = this.upClass
        break
      case "right":
        directionClass = this.rightClass
        break
      case "down":
        directionClass = this.downClass
        break
      case "left":
        directionClass = this.leftClass
        break
      default:
        throw new Error("invalid direction")
    }

    return <div className={classNames(this.triangleClass, directionClass, this.upClass, this.props.className)} />
  }

  protected setupStyles() {
    this.triangleClass = style({
      width: 0,
      height: 0
    })

    const width = this.props.height / 1.5
    const height = this.props.width

    this.upClass = style({
      borderWidth: `0 ${width}em ${height}em ${width}em`,
      borderStyle: "solid",
      borderColor: `transparent transparent ${this.props.color} transparent`
    })

    this.rightClass = style({
      borderWidth: `${width}em 0 ${width}em ${height}em`,
      borderStyle: "solid",
      borderColor: `transparent transparent transparent ${this.props.color}`
    })

    this.downClass = style({
      borderWidth: `${height}em ${width}em  0 ${width}em`,
      borderStyle: "solid",
      borderColor: `${this.props.color} transparent transparent transparent`
    })

    this.leftClass = style({
      borderLeftColor: "transparent",
      borderWidth: `${width}em ${height}em ${width}em 0`,
      borderStyle: "solid",
      borderColor: `transparent ${this.props.color} transparent transparent`
    })
  }
}
