import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import * as React from "react"
import { ViewComponent } from "../base/ViewComponent"

interface EqualsProps extends ViewComponentProps {
  width: number
  height: number
  color: csx.ColorHelper | string
  className?: string
  onClick?: (event: React.SyntheticEvent<any>) => void
}

export default class Equals extends ViewComponent<EqualsProps, {}> {
  render() {
    super.render()

    return (
      <svg
        className={this.props.className}
        width={`${this.props.width}em`}
        height={`${this.props.height}em`}
        viewBox="0 0 12 7"
        version="1.1"
      >
        <defs />
        <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="equals" transform="translate(-986.000000, -1365.000000)">
            <rect fill="#FFFFFF" x="0" y="0" width="1440" height="4514" />
            <g id="Group-18" transform="translate(984.000000, 1365.000000)" fill="#FDDB6C" fillRule="nonzero">
              <g id="Group-17" transform="translate(2.000000, 0.000000)">
                <rect id="Rectangle-23" x="0" y="0" width="12" height="2.5" rx="1.25" />
                <rect id="Rectangle-23-Copy" x="0" y="4" width="12" height="2.5" rx="1.25" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  protected setupStyles() {}
}
