import type { Doc } from "core/modules/state/model/Model"
import { Reducer as ReduxReducer } from "redux"

import { BaseModuleWithAppName } from "core/controller/Module"
import type {
  CoreActions,
  InvalidateAllViewsAction,
  InvalidateViewAction,
  ReceiveDocumentAction,
  ReceiveViewAction,
  RequestViewAction,
  SetDocumentLocalAction,
  SetDocumentsLocalAction
} from "core/modules/actions/CoreActions"
import { Logger } from "core/modules/logging/Logger"
import { ModelManagerInternal } from "core/modules/state/model/ModelManager"

export interface Reducer {
  getReducer(): ReduxReducer
}

export class CoreReducerModule extends BaseModuleWithAppName {
  get moduleName() {
    return "CoreReducer"
  }

  get dependencies() {
    return ["ModelManager"]
  }

  // Should be set up before the store so that static members have been initialized
  get setupPriority(): number {
    return 100
  }

  declare modelManager: ModelManagerInternal

  protected static coreActions: CoreActions
  protected static logger: Logger
  protected static modelManager: ModelManagerInternal

  protected static requestView(action: RequestViewAction) {
    CoreReducerModule.logger.debug("Requesting view", action.type)
  }

  protected static receiveView(action: ReceiveViewAction) {
    CoreReducerModule.logger.debug("Received view", action.parameters.type)
    CoreReducerModule.modelManager.importView(action.payload, action.parameters, action.raw, action.api)
  }

  protected static receiveDocument<T extends Doc>(action: ReceiveDocumentAction<T>) {
    if (!action.payload || !action.payload.id) {
      CoreReducerModule.logger.warning("Invalid document received in receiveDocument action.", action)
      return
    }

    CoreReducerModule.logger.debug("Received document", { type: action.parameters.type, id: action.payload.id })
    CoreReducerModule.modelManager.importDocument(action.payload, action.parameters.type!, action.inline)

    if (action.onReceiveAfter) {
      // Run any after receive handlers
      let doc = CoreReducerModule.modelManager.getDocument<T>(action.payload.id, action.parameters.type!)

      if (doc) {
        for (const handler of action.onReceiveAfter) {
          doc = handler(Object.assign({}, doc), action.parameters) || doc
        }

        CoreReducerModule.modelManager.setDocument(doc!)
      }
    }
  }

  protected static setDocumentLocal(action: SetDocumentLocalAction) {
    CoreReducerModule.logger.trace("Setting document locally", action.document)
    CoreReducerModule.modelManager.setDocument(action.document)
  }

  protected static setDocumentsLocal(action: SetDocumentsLocalAction) {
    CoreReducerModule.logger.trace("Setting documents locally", action.documents)
    if (!action.documents) return

    for (const document of action.documents) CoreReducerModule.modelManager.setDocument(document)
  }

  protected static invalidateView(action: InvalidateViewAction) {
    this.invalidateNamedView(action.viewName, action.invalidationType)
  }

  protected static invalidateAllViews(action: InvalidateAllViewsAction) {
    for (const viewName of this.modelManager.getViewNames()) {
      this.invalidateNamedView(viewName, action.invalidationType)
    }
  }

  protected static invalidateNamedView(viewName: string, invalidationType: string) {
    if (invalidationType === "unload") {
      CoreReducerModule.logger.trace("Unloading view", viewName)
      CoreReducerModule.modelManager.unloadView(viewName)
    } else if (invalidationType === "invalidateWhenUsed") {
      CoreReducerModule.logger.trace("Marking view invalid", viewName)
      CoreReducerModule.modelManager.invalidateView(viewName)
    }
  }

  setup() {
    this.logger.info("Setup core reducer")
    CoreReducerModule.logger = this.logger
    CoreReducerModule.coreActions = this.coreActions
    CoreReducerModule.modelManager = this.modelManager
  }

  getReducer(): ReduxReducer {
    return this.reduce
  }

  protected reduce(model: any = undefined, action: any) {
    try {
      switch (action.type) {
        case "RequestView":
          CoreReducerModule.modelManager.startTransaction(model)
          CoreReducerModule.requestView(<RequestViewAction>action)
          break
        case "ReceiveView":
          CoreReducerModule.modelManager.startTransaction(model)
          CoreReducerModule.receiveView(<ReceiveViewAction>action)
          break
        case "InvalidateView":
          CoreReducerModule.modelManager.startTransaction(model)
          CoreReducerModule.invalidateView(<InvalidateViewAction>action)
          break
        case "InvalidateAllViews":
          CoreReducerModule.modelManager.startTransaction(model)
          CoreReducerModule.invalidateAllViews(<InvalidateAllViewsAction>action)
          break
        case "ReceiveDocument":
          CoreReducerModule.modelManager.startTransaction(model)
          CoreReducerModule.receiveDocument(<ReceiveDocumentAction<any>>action)
          break
        case "SetDocumentLocal":
          CoreReducerModule.modelManager.startTransaction(model)
          CoreReducerModule.setDocumentLocal(<SetDocumentLocalAction>action)
          break
        case "SetDocumentsLocal":
          CoreReducerModule.modelManager.startTransaction(model)
          CoreReducerModule.setDocumentsLocal(<SetDocumentsLocalAction>action)
          break
        case "RemoveDocumentLocal":
          CoreReducerModule.modelManager.startTransaction(model)
          CoreReducerModule.logger.trace("Removing document locally", action.payload)
          CoreReducerModule.modelManager.removeDocument(action.payload)
          break
        case "@@redux/INIT":
          if (!CoreReducerModule.modelManager.model) CoreReducerModule.modelManager.startTransaction(model)
          break
        default:
      }
    } catch (e) {
      CoreReducerModule.logger.error("Failed to apply reducer", e)
    } finally {
      CoreReducerModule.modelManager.endTransaction()
    }

    CoreReducerModule.logger.debug("Reducer returning model", CoreReducerModule.modelManager.model)
    return CoreReducerModule.modelManager.model || null
  }
}
