import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"

import { Fragment } from "react"
import { appName } from "../../AppName"
import { LogicComponent } from "../base/LogicComponent"

export interface NestedListItem {
  children?: NestedListItem[]
  title?: string
  value?: string
  disabled?: boolean
}

interface NestedListProps extends LogicComponentProps {
  // Todo: add value property and implement displaying selected item if needed
  depth?: number
  emptyOptionLabel?: string
  listItems: NestedListItem[]
  onChange?: (item: string) => void
  showEmptyOption?: boolean
  collapsable?: boolean
  selectable?: boolean
}

interface NestedListState extends LogicComponentState {
  openItems: { [key: string]: boolean }
  selectedItem?: string
}

export default class NestedList extends LogicComponent<NestedListProps, NestedListState> {
  constructor(props: NestedListProps) {
    super(props)

    this.state = { openItems: {} }
  }

  render() {
    const { listItems, depth } = this.props

    return this.renderList(listItems, depth || 1)
  }

  protected renderList(items: NestedListItem[], depth: number, parent?: NestedListItem) {
    const { showEmptyOption } = this.props

    const menuItems = this.renderMenuItems(items, depth, parent)

    if (!menuItems || (menuItems.length === 0 && !showEmptyOption)) return false

    return <List component="nav">{menuItems}</List>
  }

  protected renderMenuItems(items: NestedListItem[], depth: number, parent?: NestedListItem) {
    const { showEmptyOption, emptyOptionLabel, selectable } = this.props

    const emptyOption: NestedListItem = {
      value: undefined,
      title: emptyOptionLabel ?? this.txt(appName, "components", "ui", "form", "BaseInput", "none")
    }

    if (showEmptyOption && !parent && selectable) items = [emptyOption, ...items]

    return (items || []).map(menuItem => {
      if (!!menuItem.children?.length) {
        return this.renderMenuItemWithChildren(menuItem, depth)
      } else {
        return this.renderMenuItem(menuItem, depth)
      }
    })
  }

  protected renderMenuItem(menuItem: NestedListItem, depth: number) {
    const { onChange, selectable } = this.props

    return (
      <ListItem
        style={{ paddingLeft: `${depth}rem` }}
        data-value={menuItem.value}
        key={`${depth}-${menuItem.value}-${selectable ? "selectable" : "not-selectable"}}`}
        // Type of button is true.
        button={selectable as true}
        disabled={menuItem.disabled}
        onClick={selectable ? () => onChange?.(menuItem.value!) : undefined}
      >
        <ListItemText primary={menuItem.title} />
      </ListItem>
    )
  }

  protected renderMenuItemWithChildren(menuItem: NestedListItem, depth: number) {
    const { openItems } = this.state
    const { collapsable } = this.props

    return (
      <Fragment key={`${depth}-${menuItem.value}`}>
        <ListItem
          style={{ paddingLeft: `${depth}rem` }}
          data-value={menuItem.value}
          button
          disabled={menuItem.disabled}
          onClick={this.onClickParentMenu}
        >
          <ListItemText primary={menuItem.title} />
          {collapsable && (openItems[menuItem.value!] ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>

        {collapsable && menuItem.children && (
          <Collapse in={openItems[menuItem.value!]} timeout="auto" unmountOnExit>
            {this.renderList(menuItem.children, depth + 1, menuItem)}
          </Collapse>
        )}
        {!collapsable && menuItem.children && this.renderList(menuItem.children, depth + 1, menuItem)}
      </Fragment>
    )
  }

  protected onClickParentMenu = event => {
    const { onChange, selectable, collapsable } = this.props
    const value = event.currentTarget.dataset.value

    if (!value) return

    if (selectable && !collapsable && onChange) {
      onChange(value)
    }

    this.updateState(state => {
      state.openItems[value] = !state.openItems[value]
    })
  }
}
