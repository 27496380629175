import { Box, Typography } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import { style } from "typestyle"

import { Survey } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Button from "lib/ui/components/form/Button"
import TextField from "lib/ui/components/form/TextField"
import CardLayout from "lib/ui/components/layout/CardLayout"
import LanguageSelector from "lib/ui/components/settings/LanguageSelector"

import Grid from "../../../../../../lib/ui/components/layout/grid/Grid"
import { ViewComponent } from "../../../base/ViewComponent"
import QuestionsProgress from "../questions/QuestionsProgress"

interface Props extends ViewComponentProps {
  background?: string
  survey: Survey
  maxPage: number
  language: string
  logo: string
  organisationSurveyTags: string[]
  email?: string
  token: string
  error?: string
  onNext: () => void
  onPrevious: () => void
  onSetLanguage: (language: string) => void
  onLogout: () => void
  onSetToken: (token: string) => void
  onResendEmail: () => void
}

export class ValidateEmailPageView extends ViewComponent<Props & WithStyles<typeof styles>> {
  readonly minTokenLength = 6

  get componentName() {
    return ["survey", "pages", "validate_email", "ValidateEmailPageView"]
  }

  render() {
    super.render()
    return this.isMobile ? this.renderMobile() : this.renderDesktop()
  }

  private renderMobile() {
    const { classes } = this.props

    return (
      <div className={classes.mobileLayout}>
        {this.renderHeaderMobile()}
        {this.renderMain()}
      </div>
    )
  }

  private renderDesktop() {
    const { background, classes } = this.props

    return (
      <CardLayout
        maxWidth="50rem"
        width="100%"
        header={this.renderHeaderDesktop()}
        background={this.theme.background}
        headerBackgroundColor={this.theme.colors.windowColor}
      >
        <div className={classes.cardContent}>{this.renderMain()}</div>
      </CardLayout>
    )
  }

  private renderHeaderDesktop() {
    const { classes, language, onSetLanguage, survey, maxPage } = this.props

    return (
      <div className={classes.topContainerOuter}>
        <div className={classes.topContainerDesktop}>
          <div>
            <Typography color="textSecondary">
              {this.txt(survey.title)} &bull; {0}/{maxPage + 1}
            </Typography>

            {this.renderTitle()}
          </div>
          <div>
            {this.renderLogo()}

            <LanguageSelector
              className={classes.languageSelector}
              id="language-selector"
              language={language}
              setLanguage={onSetLanguage}
            />
          </div>
        </div>

        <Divider className={classes.hr} />
      </div>
    )
  }

  private renderHeaderMobile() {
    const { classes, language, onSetLanguage, survey, maxPage } = this.props

    return (
      <div>
        <div className={classes.topContainerMobile}>
          {this.renderLogo()}

          <LanguageSelector
            className={classes.languageSelector}
            id="language-selector"
            language={language}
            setLanguage={onSetLanguage}
          />
        </div>
        <Typography>{this.txt(survey.title)}</Typography>
        <Typography color="textSecondary">{`0/${maxPage + 1}`}</Typography>

        <Divider className={classes.hr} />
      </div>
    )
  }
  private renderLogo() {
    const { logo, classes } = this.props

    return <img alt="Hintsa" className={classes.logo} src={logo} />
  }

  private renderMain() {
    super.render()

    return (
      <>
        {this.renderMainBody()}
        {this.renderCommandButtons()}
      </>
    )
  }

  private renderMainBody() {
    const { classes } = this.props
    return <Box className={classes.personalDetails}>{this.renderEmailValidation()}</Box>
  }

  private renderTitle() {
    const { classes } = this.props
    return (
      <Typography className={classes.categoryTitle} component="h1" variant="h2">
        {this.txt("email_validate")}
      </Typography>
    )
  }

  private renderEmailValidation() {
    const { email } = this.props
    return (
      <Grid container>
        <Grid sm={12} xs={12}>
          <p>
            {this.txt("email_token_sent")} {email}. <br />
            {this.txt("email_token_please_enter")}
          </p>
        </Grid>
        <>
          <Grid sm={4} xs={12}>
            {this.renderEmailValidationBox()}
          </Grid>
          <Grid sm={8} xs={12}></Grid>
        </>
        {this.renderError()}
        <Grid sm={12} xs={12}>
          <p>
            {this.txt("email_check_spam")} <br />
            {this.txt("email_to_resend")},{" "}
            <a href="#" onClick={this.resendEmail}>
              {this.txt("click_this_link")}
            </a>
            .
          </p>
        </Grid>
      </Grid>
    )
  }

  private renderError() {
    if (!this.props.error) return undefined

    const errorClass = style({
      minHeight: "2em",
      textAlign: "center",
      paddingTop: "0.25em",
      color: this.theme.colors.errorColor.toString(),
      backgroundColor: this.theme.colors.errorColor.fade(0.2).toString()
    })

    return (
      <Grid sm={12} xs={12}>
        <div className={errorClass}>{this.props.error}</div>
      </Grid>
    )
  }

  private resendEmail = e => {
    e.preventDefault()
    const { onResendEmail } = this.props
    onResendEmail()
  }

  private renderEmailValidationBox() {
    const { token, onSetToken } = this.props
    return (
      <>
        <TextField
          margin
          value={token.toUpperCase()}
          label={this.txt("email_token")}
          required
          error={token.length < this.minTokenLength}
          length={20}
          fullWidth
          onChange={onSetToken}
        />
      </>
    )
  }

  private renderCommandButtons() {
    const { classes, onNext, onPrevious, maxPage } = this.props
    return (
      <div
        id="buttonContainer"
        className={this.isMobile ? classes.buttonContainerMobile : classes.buttonContainerDesktop}
      >
        <Button onClick={onPrevious} variant="secondary">
          {this.txt("core", "components", "ui", "form", "previous")}
        </Button>

        {!this.isMobile && <QuestionsProgress progress={0} maxItems={maxPage + 1} />}

        <Button onClick={onNext} disabled={!this.validateValues()}>
          {this.txt("core", "components", "ui", "form", "next")}
        </Button>
      </div>
    )
  }

  private validateValues() {
    const { token } = this.props
    if (!token) {
      return false
    }
    return token.length >= this.minTokenLength
  }
}

const styles = () =>
  createStyles({
    progress: {
      fontSize: "16px",
      width: "6em",
      marginTop: "1em",
      textAlign: "right"
    },
    hr: {
      marginBottom: "1rem",
      marginTop: "1rem"
    },
    categoryTitle: {
      fontWeight: "bold"
    },
    languageSelector: {
      justifyContent: "flex-end"
    },
    cardContent: {
      padding: "0 2rem 2rem 2rem",
      display: "flex",
      flexDirection: "column"
    },
    personalDetails: {
      paddingLeft: 0,
      padding: "1rem 0 2rem 0"
    },
    title: {
      marginTop: 0,
      fontSize: "20px"
    },
    buttonContainerDesktop: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // "invisible" padding to improve auto scroll behavior
      paddingBottom: "1rem",
      marginBottom: "-1rem"
    },
    buttonContainerMobile: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      paddingBottom: "1rem",
      alignItems: "flex-end"
    },
    topContainerOuter: {
      margin: "2.5rem 2.5rem 0 2.5rem"
    },
    topContainerDesktop: {
      display: "flex",
      justifyContent: "space-between"
    },
    topContainerMobile: {
      justifyContent: "space-between",
      minHeight: "4em",
      display: "flex"
    },
    topContainerColumn: {
      display: "flex",
      flexDirection: "column"
    },
    logo: {
      width: 110,
      height: 35
    },
    mobileLayout: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      margin: "1.5rem",
      flexGrow: 1
    },
    radioQuestion: {
      marginLeft: "1rem"
    },
    gridPaddingRight: {
      paddingRight: "1rem"
    }
  })

export default withStyles<any, any, Props>(styles)(ValidateEmailPageView)
