import {
  ViewComponent as BaseViewComponent,
  type ViewComponentProps,
  type ViewComponentState
} from "core/components/base/ViewComponent"

export abstract class ViewComponent<
  P extends ViewComponentProps,
  S extends ViewComponentState = {}
> extends BaseViewComponent<P, S> {
  get appName() {
    return "ui"
  }
}
