import { SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import Circle from "lib/ui/components/symbols/Circle"
import Comparison from "lib/ui/components/symbols/Comparison"
import Typography from "lib/ui/components/typography/Typography"
import { getValueColor } from "lib/utils/report/WellbeingCommonReportUtils"
import { getCategoryAverageScore } from "lib/utils/report/WellbeingPersonalReportUtils"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import {
  ReportVariant,
  reportBodyClass,
  reportMediumHeadingClass,
  reportSmallBodyClass,
  reportSubHeadingClass
} from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  language: string
  reportVariant: ReportVariant
}

const reportBenchmarkData = {
  pa: 2.7,
  nu: 2.9,
  sr: 3.5,
  bm: 3.3,
  me: 3.4,
  gh: 4.3,
  co: 3.9
}

export default class ReportSectionCoblCircle extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionCoblCircle"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }
  render() {
    const { reportVariant } = this.props

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid container className="same-page">
          <Grid spacer spacerSize={{ desktop: 5, pdf: 10, mobile: 0 }[reportVariant]} />
          {this.renderCoblCircle()}
          {reportVariant === "mobile" && this.renderInterpretingResults()}
          {this.renderCoblIntro()}
          {reportVariant !== "mobile" && this.renderInterpretingResults()}
        </Grid>
      </>
    )
  }

  private renderCoblCircle() {
    return (
      <Grid xs={12} md={7} className={styles.coblGrid}>
        <div className={styles.coblContainer}>
          <div className={styles.coblImage} style={{ backgroundImage: `url(${this.getCoblSrc()})` }} />
          <div className={styles.coblScoreContainer}>
            {this.renderCoblCircleCurrent()}
            {this.renderCoblCircleComparison()}
          </div>
        </div>
      </Grid>
    )
  }

  private renderCoblCircleCurrent() {
    const { answer } = this.props

    return (
      <>
        <div className={classNames(styles.coblScore, styles.coblScoreGh)}>
          <Typography
            style={{
              color: getValueColor(getCategoryAverageScore(answer?.answers, "gh"), this.theme)?.toString()
            }}
            className={classNames(styles.coblScoreTypography)}
          >
            {getCategoryAverageScore(answer?.answers, "gh")?.toFixed(1)}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblScoreNu)}>
          <Typography
            style={{
              color: getValueColor(getCategoryAverageScore(answer?.answers, "nu"), this.theme)?.toString()
            }}
            className={classNames(styles.coblScoreTypography)}
          >
            {getCategoryAverageScore(answer?.answers, "nu")?.toFixed(1)}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblScoreMe)}>
          <Typography
            style={{
              color: getValueColor(getCategoryAverageScore(answer?.answers, "me"), this.theme)?.toString()
            }}
            className={classNames(styles.coblScoreTypography)}
          >
            {getCategoryAverageScore(answer?.answers, "me")?.toFixed(1)}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblScoreBm)}>
          <Typography
            style={{
              color: getValueColor(getCategoryAverageScore(answer?.answers, "bm"), this.theme)?.toString()
            }}
            className={classNames(styles.coblScoreTypography)}
          >
            {getCategoryAverageScore(answer?.answers, "bm")?.toFixed(1)}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblScoreSr)}>
          <Typography
            style={{
              color: getValueColor(getCategoryAverageScore(answer?.answers, "sr"), this.theme)?.toString()
            }}
            className={classNames(styles.coblScoreTypography)}
          >
            {getCategoryAverageScore(answer?.answers, "sr")?.toFixed(1)}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblScorePa)}>
          <Typography
            style={{
              color: getValueColor(getCategoryAverageScore(answer?.answers, "pa"), this.theme)?.toString()
            }}
            className={classNames(styles.coblScoreTypography)}
          >
            {getCategoryAverageScore(answer?.answers, "pa")?.toFixed(1)}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblScoreCo)}>
          <Typography
            style={{
              color: getValueColor(getCategoryAverageScore(answer?.answers, "co"), this.theme)?.toString()
            }}
            className={classNames(styles.coblScoreTypography)}
          >
            {getCategoryAverageScore(answer?.answers, "co")?.toFixed(1)}
          </Typography>
        </div>

        <div className={classNames(styles.coblScore, styles.coblBenchmarkScore, styles.coblScoreBenchmarkGh)}>
          <Typography
            style={{
              color: ReportSectionCoblCircle.theme.colors.report.report_benchmark.toString()
            }}
            className={classNames(styles.coblScoreTypographySmall)}
          >
            {`${reportBenchmarkData.gh.toFixed(1)}*`}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblBenchmarkScore, styles.coblScoreBenchmarkNu)}>
          <Typography
            style={{
              color: ReportSectionCoblCircle.theme.colors.report.report_benchmark.toString()
            }}
            className={classNames(styles.coblScoreTypographySmall)}
          >
            {`${reportBenchmarkData.nu.toFixed(1)}*`}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblBenchmarkScore, styles.coblScoreBenchmarkMe)}>
          <Typography
            style={{
              color: ReportSectionCoblCircle.theme.colors.report.report_benchmark.toString()
            }}
            className={classNames(styles.coblScoreTypographySmall)}
          >
            {`${reportBenchmarkData.me.toFixed(1)}*`}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblBenchmarkScore, styles.coblScoreBenchmarkBm)}>
          <Typography
            style={{
              color: ReportSectionCoblCircle.theme.colors.report.report_benchmark.toString()
            }}
            className={classNames(styles.coblScoreTypographySmall)}
          >
            {`${reportBenchmarkData.bm.toFixed(1)}*`}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblBenchmarkScore, styles.coblScoreBenchmarkSr)}>
          <Typography
            style={{
              color: ReportSectionCoblCircle.theme.colors.report.report_benchmark.toString()
            }}
            className={classNames(styles.coblScoreTypographySmall)}
          >
            {`${reportBenchmarkData.sr.toFixed(1)}*`}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblBenchmarkScore, styles.coblScoreBenchmarkPa)}>
          <Typography
            style={{
              color: ReportSectionCoblCircle.theme.colors.report.report_benchmark.toString()
            }}
            className={classNames(styles.coblScoreTypographySmall)}
          >
            {`${reportBenchmarkData.pa.toFixed(1)}*`}
          </Typography>
        </div>
        <div className={classNames(styles.coblScore, styles.coblBenchmarkScore, styles.coblScoreBenchmarkCo)}>
          <Typography
            style={{
              color: ReportSectionCoblCircle.theme.colors.report.report_benchmark.toString()
            }}
            className={classNames(styles.coblScoreTypographySmall)}
          >
            {`${reportBenchmarkData.co.toFixed(1)}*`}
          </Typography>
        </div>
      </>
    )
  }

  private renderCoblCircleComparison() {
    const { answer, comparison } = this.props

    if (!comparison) return null

    const comparisonInfos = [
      {
        category: "gh",
        top: "-13.6125rem",
        left: "-9rem",
        transform: `rotate(-30deg)`,
        score: getCategoryAverageScore(answer?.answers, "gh"),
        comparisonScore: getCategoryAverageScore(comparison?.answers, "gh")
      },
      {
        category: "nu",
        top: `-13.6125rem`,
        left: `5.875rem`,
        transform: `rotate(30deg)`,
        score: getCategoryAverageScore(answer?.answers, "nu"),
        comparisonScore: getCategoryAverageScore(comparison?.answers, "nu")
      },
      {
        category: "me",
        top: `-2.5rem`,
        left: `12rem`,
        score: getCategoryAverageScore(answer?.answers, "me"),
        comparisonScore: getCategoryAverageScore(comparison?.answers, "me")
      },
      {
        category: "bm",
        top: `12.25rem`,
        left: `5.875rem`,
        transform: `rotate(-30deg)`,
        score: getCategoryAverageScore(answer?.answers, "bm"),
        comparisonScore: getCategoryAverageScore(comparison?.answers, "bm")
      },
      {
        category: "sr",
        top: "12.25rem",
        left: "-9rem",
        transform: `rotate(30deg)`,
        score: getCategoryAverageScore(answer?.answers, "sr"),
        comparisonScore: getCategoryAverageScore(comparison?.answers, "sr")
      },
      {
        category: "pa",
        top: "-2.5rem",
        left: "-15.25rem",
        score: getCategoryAverageScore(answer?.answers, "pa"),
        comparisonScore: getCategoryAverageScore(comparison?.answers, "pa")
      },
      {
        category: "co",
        top: "-4.875rem",
        left: "-1.5rem",
        score: getCategoryAverageScore(answer?.answers, "co"),
        comparisonScore: getCategoryAverageScore(comparison?.answers, "co")
      }
    ]

    return comparisonInfos
      .map(info => {
        if (info.score === undefined || info.comparisonScore === undefined) return null

        return (
          <Comparison
            key={info.category}
            top={info.top}
            left={info.left}
            transform={info.transform}
            score={info.score}
            comparisonScore={info.comparisonScore}
          />
        )
      })
      .filter(Boolean)
  }

  renderCoblIntro() {
    const { reportVariant } = this.props

    return (
      <>
        <Grid sm={12} md={5}>
          <Grid spacer spacerSize={{ desktop: 0, mobile: 2, pdf: 8 }[reportVariant]} />
          <Typography useContrastColor className={reportSubHeadingClass}>
            {this.txt("overview")}
          </Typography>
          <Typography variant="heading" useContrastColor className={reportMediumHeadingClass}>
            {this.txt("cobl_title")}
          </Typography>
          <Typography useContrastColor component="div" className={reportBodyClass}>
            <Markdown content={this.txt("cobl_intro")} />
          </Typography>
        </Grid>
      </>
    )
  }

  renderInterpretingResults() {
    const { reportVariant } = this.props

    return (
      <>
        <Grid sm={12}>
          <Grid spacer spacerSize={reportVariant === "mobile" ? 1 : 4} />
          <Typography
            variant="body"
            useContrastColor
            className={classNames(reportSmallBodyClass, styles.interpretingResults)}
          >
            {this.txt("interpreting_results")}
          </Typography>
        </Grid>
        <Grid sm={4}>
          <div className={styles.resultsLabel}>
            <Circle
              diameter="1.5rem"
              className={styles.circle}
              color={ReportSectionCoblCircle.theme.colors.report.report_excellent.toString()}
            />
            <Typography variant="body" useContrastColor className={reportSmallBodyClass}>
              {this.txt("results_excellent")}
            </Typography>
          </div>
          <div className={styles.resultsLabel}>
            <Circle
              diameter="1.5rem"
              className={styles.circle}
              color={ReportSectionCoblCircle.theme.colors.report.report_well_done.toString()}
            />
            <Typography variant="body" useContrastColor className={reportSmallBodyClass}>
              {this.txt("results_well_done")}
            </Typography>
          </div>
        </Grid>
        <Grid sm={4}>
          <div className={styles.resultsLabel}>
            <Circle
              diameter="1.5rem"
              className={styles.circle}
              color={ReportSectionCoblCircle.theme.colors.report.report_neutral.toString()}
            />
            <Typography variant="body" useContrastColor className={reportSmallBodyClass}>
              {this.txt("results_neutral")}
            </Typography>
          </div>
          <div className={styles.resultsLabel}>
            <Circle
              diameter="1.5rem"
              className={styles.circle}
              color={ReportSectionCoblCircle.theme.colors.report.report_need_attention.toString()}
            />
            <Typography variant="body" useContrastColor className={reportSmallBodyClass}>
              {this.txt("results_need_attention")}
            </Typography>
          </div>
        </Grid>
        <Grid sm={4} className={styles.interpretingResultsItem}>
          <div className={styles.resultsLabel}>
            <Circle
              diameter="1.5rem"
              className={styles.circle}
              color={ReportSectionCoblCircle.theme.colors.report.report_need_extra_focus.toString()}
            />
            <Typography variant="body" useContrastColor className={reportSmallBodyClass}>
              {this.txt("results_need_extra_focus")}
            </Typography>
          </div>
        </Grid>
        <Grid sm={12}>
          <Typography
            useContrastColor
            variant="body"
            className={classNames(reportSmallBodyClass, styles.benchmarkExplanation)}
          >
            {this.txt("benchmark_explanation")}
          </Typography>
        </Grid>
      </>
    )
  }

  private getCoblSrc() {
    const { language } = this.props

    return `${this.appConfig.imagesRoot}/cobl_circle/cobl_${language}_w.svg`
  }
}

let styles

const setupStyles = (variant: ReportVariant) => {
  styles = stylesheet({
    coblContainer: {
      width: "100%",
      height: "26rem",
      minWidth: "26rem",
      marginTop: "-1rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "3rem 3rem 0rem 3rem",
      scale: variant === "mobile" ? "70%" : undefined
    },
    coblImage: {
      height: "40rem",
      width: "24rem",
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    },

    benchmarkExplanation: {
      color: csx.important(ReportSectionCoblCircle.theme.colors.report.report_benchmark.toString())
    },
    interpretingResultsItem: {
      display: "flex",
      alignItems: "flex-start",
      marginTop: "-1rem",
      marginBottom: "-1rem",
      fontWeight: "bold"
    },
    resultsLabel: {
      display: "flex",
      alignItems: "center"
    },
    coblGrid: {
      display: "flex",
      justifyContent: "center"
    },
    circle: {
      marginRight: "0.5rem"
    },
    coblScoreContainer: {
      position: "absolute"
    },
    coblScore: {
      position: "absolute",
      width: "5rem",
      display: "flex",
      justifyContent: "center"
    },
    coblScoreTypography: {
      margin: 0,
      fontSize: "36px",
      fontWeight: 600,
      lineHeight: "120%"
    },
    coblScoreTypographySmall: {
      margin: 0,
      fontSize: "16px",
      lineHeight: "120%"
    },
    coblScoreGh: {
      top: "-12.5rem",
      left: "-9rem",
      transform: `rotate(-30deg)`
    },
    coblScoreNu: {
      top: `-12.5rem`,
      left: `4rem`,
      transform: `rotate(30deg)`
    },
    coblScoreMe: {
      top: `-1rem`,
      left: `11rem`
    },
    coblScoreBm: {
      top: `9.75rem`,
      left: `3.75rem`,
      transform: `rotate(-30deg)`
    },
    coblScoreSr: {
      top: "9.75rem",
      left: "-9rem",
      transform: `rotate(30deg)`
    },
    coblScorePa: {
      top: "-1rem",
      left: "-16rem"
    },
    coblScoreCo: {
      top: "-3.25rem",
      left: "-2.5rem"
    },
    coblBenchmarkScore: {},
    coblScoreBenchmarkGh: {
      top: "-7rem",
      left: "-6rem",
      transform: `rotate(-30deg)`
    },
    coblScoreBenchmarkNu: {
      top: `-6.75rem`,
      left: `1.25rem`,
      transform: `rotate(30deg)`
    },
    coblScoreBenchmarkMe: {
      top: `-0.25rem`,
      left: `4.25rem`
    },
    coblScoreBenchmarkBm: {
      top: "5.65rem",
      left: `1.25rem`,
      transform: `rotate(-30deg)`
    },
    coblScoreBenchmarkSr: {
      top: "5.75rem",
      left: "-6rem",
      transform: `rotate(30deg)`
    },
    coblScoreBenchmarkPa: {
      top: "-0.25rem",
      left: "-9.25rem"
    },
    coblScoreBenchmarkCo: {
      top: "1.75rem",
      left: "-2.375rem"
    },
    interpretingResults: {
      fontWeight: "bold"
    }
  })
}
