import { BaseModuleWithAppName } from "core/controller/Module"
import type { ViewParameters } from "core/modules/actions/CoreActions"
import type { ConversionMapModule } from "core/modules/state/conversionmap/ConversionMap"
import type { Doc } from "core/modules/state/model/Model"
import type { ModelManagerInternal } from "core/modules/state/model/ModelManager"

/**
 * Converts documents between API JSON and internal object format
 */
export interface DocumentConverter {
  /**
   * Import document and any related documents into the model.
   */
  importDocument<T extends Doc>(document: T, documentType: string, inline?: boolean): T

  importView<T extends Doc>(
    viewName: string,
    viewData: T[] | { [itemsKey: string]: T[] },
    parameters: any,
    viewDataRaw: any,
    onImportDocument: (document: T, previusDocument?: T) => void
  ): void

  /**
   * Return document in a format understood by an external system.
   */
  exportDocument<T extends Doc>(document: T, documentType: string): Omit<T, "id" | "__type"> & { id?: number }

  exportLinkedDocument<T extends Doc>(document: T): T
}

export class DocumentConverterModule extends BaseModuleWithAppName implements DocumentConverter {
  declare conversionMap: ConversionMapModule
  declare modelManager: ModelManagerInternal

  get moduleName() {
    return "DocumentConverter"
  }

  get dependencies() {
    return ["ConversionMap", "ModelManager"]
  }

  importDocument<T extends Doc>(document: T, documentType: string, inline?: boolean, compact: boolean = false): T {
    this.logger.trace("Importing document", { documentType, document })

    if (!document.id) {
      const nextId = this.modelManager.getNextDocumentId()
      this.logger.debug("Importing document without id. Using generated local id", nextId)
      document.id = nextId
    } else {
      document.id = document.id.toString()
    }
    document.__compact = compact
    document.__type = documentType

    this.convertDocumentValues(document, documentType)

    if (!inline) this.storeDocument(document)

    this.logger.trace("Imported document", document)

    return document
  }

  importView<T extends Doc>(
    viewName: string,
    viewData: T[] | { [itemsKey: string]: T[] },
    parameters: ViewParameters,
    viewDataRaw: any,
    onImportDocument: (document: T, previousDocument?: T) => void
  ) {
    this.logger.startGroup(`Import view: ${viewName}`, false)

    // Get array or documents or default to empty array
    const documents: T[] = (parameters.itemsKey ? viewData[parameters.itemsKey] : viewData) || []
    const conversionMapCollection = this.conversionMap.map[viewName]

    const documentType = conversionMapCollection.__api?.documentType
    if (!documentType) {
      this.logger.error("View in conversion maps is missing document type property (documentType)", viewName)
      this.logger.endGroup()
      return
    }

    try {
      const viewContainer = this.modelManager.initializeView(viewName, documentType)
      viewContainer.parameters = parameters
      viewContainer.raw = viewDataRaw

      for (const document of documents) {
        if (!document) continue

        const previousDocument = this.modelManager.getDocument<T>(document.id, documentType)
        const importedDocument = this.importDocument(document, documentType)
        viewContainer.push(importedDocument)

        onImportDocument(importedDocument, previousDocument)
      }
    } finally {
      this.logger.endGroup()
    }
  }

  exportDocument<T extends Doc>(document: T, documentType: string): Omit<T, "id" | "__type"> & { id?: number } {
    const converters = this.conversionMap.map[documentType]

    this.logger.debug("Exporting document", { documentType, document })

    const exportableDocument = { ...document }

    if (converters) {
      for (const key of Object.keys(converters)) {
        if (key.startsWith("__")) continue

        try {
          converters[key].export(exportableDocument, key, this)
        } catch (error) {
          this.logger.error("Failed to export document value", { error, key, document: exportableDocument })
        }
      }
    } else {
      this.logger.debug(
        "No conversion maps for document. Not doing any mapping from internal types to JSON.",
        documentType
      )
    }

    const { __type, ...exportedDocument } = exportableDocument
    if (exportedDocument.id) {
      // @ts-ignore
      exportedDocument.id = Number(exportedDocument.id)
    }

    this.logger.debug("Exporting document (after conversion)", exportedDocument)

    return exportedDocument as Omit<T, "id" | "__type"> & { id?: number }
  }

  exportLinkedDocument<T extends Doc>(document: T): T {
    const documentType = document.__type
    const converters = documentType ? this.conversionMap.map[documentType] : {}

    this.logger.debug("Exporting linked document", { documentType, document })

    const exportedDocument = { ...document }

    if (converters) {
      for (const key of Object.keys(converters)) {
        if (key.startsWith("__")) continue

        try {
          if (converters[key].exportForLinked) converters[key].export(exportedDocument, key, this)
        } catch (error) {
          this.logger.error("Failed to export document value", { error, key, document: exportedDocument })
        }
      }
    } else {
      this.logger.debug(
        "No conversion maps for document. Not doing any mapping from internal types to JSON.",
        documentType
      )
    }

    this.logger.debug("Exporting linkeddocument (after conversion)", exportedDocument)

    return exportedDocument
  }

  private convertDocumentValues<T extends Doc>(document: T, documentType: string) {
    const converters = this.conversionMap.map[documentType]
    if (converters) {
      for (const key of Object.keys(converters)) {
        if (key.startsWith("__")) continue

        try {
          converters[key].import(document, key, this)
        } catch (error) {
          this.logger.error("Failed to import document value", { key, document, error })
        }
      }
    } else {
      this.logger.debug(
        "No conversion maps for document. Not doing any mapping from JSON to internal types.",
        documentType
      )
    }
  }

  private storeDocument<T extends Doc>(document: T) {
    this.modelManager.setDocument<T>(document)
  }
}
