import type { ReactNode } from "react"

import { QuestionOption } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"
import Dropdown, { DropdownOption } from "lib/ui/components/form/Dropdown"

import { ViewComponent } from "../../base/ViewComponent"

interface OrderedDropdownQuestionViewProps extends ViewComponentProps {
  options: QuestionOption[]
  selected?: string[]
  minOptions: number
  maxOptions: number
  disabled?: boolean
  onChange: (optionIds: string[]) => void
}

interface OrderedDropdownQuestionViewState extends ViewComponentState {
  selections: string[]
}

export default class OrderedDropdownQuestionView extends ViewComponent<
  OrderedDropdownQuestionViewProps,
  OrderedDropdownQuestionViewState
> {
  constructor(props: OrderedDropdownQuestionViewProps) {
    super(props)

    this.state = { selections: props.selected ?? [] }
  }

  get componentName() {
    return ["survey", "ui", "questions", "OrderedDropdownQuestionView"]
  }

  render() {
    const { disabled, maxOptions, minOptions } = this.props
    super.render()

    const dropDowns: ReactNode[] = []
    for (let i = 0; i < maxOptions; i++) {
      const value: string | undefined = this.state.selections[i]

      // Allow slecting only the next selection
      const previousSelectionMissing = i > 0 && !this.state.selections[i - 1]

      // Allow empty when this is the last selection and never allow to unselect the first option
      const allowEmptySelection = !this.state.selections[i + 1] && !!this.state.selections[i] && i > 0

      dropDowns.push(
        <li key={i.toString()}>
          <Dropdown
            options={this.getOptions(value)}
            allowEmptySelection={allowEmptySelection}
            disabled={disabled || previousSelectionMissing}
            value={value}
            required={i <= minOptions}
            fullWidth
            onChange={selection => this.setSelection(i, selection)}
          />
        </li>
      )
    }

    return <ol>{dropDowns}</ol>
  }

  private setSelection = (index: number, value?: string) => {
    this.updateState(state => {
      if (value) {
        // Set value
        state.selections[index] = value
      } else {
        // Remove value
        state.selections.splice(index, 1)
      }
    }, this.updateParentWithSelectedOptions)
  }

  private updateParentWithSelectedOptions = () => {
    if (this.state.selections.length >= this.props.minOptions) {
      this.props.onChange(this.state.selections)
    }
  }

  private getOptions(selectedValue: string | null): DropdownOption[] {
    const { options } = this.props
    const { selections } = this.state

    return options
      .map(option => ({ label: this.txt(option.title), value: option.id!.toString() }))
      .filter(option => option.value === selectedValue || !selections.includes(option.value))
  }
}
