import { Answer } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Grid from "lib/ui/components/layout/grid/Grid"
import { Glyph } from "lib/ui/components/symbols"
import Typography from "lib/ui/components/typography/Typography"
import {
  getAnswerMaxPoints,
  getAnswerMinPoints,
  getAnswerPoints,
  getAnswerTitle,
  getScoreColorSetpointsForAnswer
} from "lib/utils/report/WellbeingPersonalReportUtils"
import React from "react"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportBodyClass, reportSmallBodyClass, reportSmallHeadingClass } from "../../WellbeingReport"
import ScoreMeter from "../../ui/ScoreMeter"

type SurvivingPerformingThrivingVariant = "surviving" | "performing" | "thriving"

interface Props extends ViewComponentProps {
  answers: Answer[]
  comparison: Answer[] | undefined
  variant: SurvivingPerformingThrivingVariant
  reportVariant: ReportVariant
}

export default class SurvivingPerformingThrivingContainer extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "SurvivingPerformingThrivingContainer"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    const { reportVariant } = this.props

    return (
      <div className={styles.containerOuter}>
        <div className={reportVariant === "mobile" ? undefined : styles.containerInner}>
          <Grid container>
            {this.renderTotalScore()}
            {this.renderAnswers()}
          </Grid>
        </div>
      </div>
    )
  }

  private renderTotalScore() {
    const { variant, reportVariant } = this.props

    return (
      <>
        <Grid sm={12}>
          <Typography className={classNames(reportSmallHeadingClass, styles.heading)} useContrastColor>
            {this.txt(`${variant}_title`)}
          </Typography>
          <Typography className={classNames(reportBodyClass, styles.heading)} useContrastColor>
            {this.txt(`${variant}_description`)}
          </Typography>
        </Grid>
        <Grid xs={12} md={6} className={styles.scoreLeft}>
          <ScoreMeter
            showTopScoreArea
            showScoreIndex
            score={this.getScore()}
            scoreComparison={this.getScore(true)}
            minScore={variant === "performing" ? 4 : 3}
            maxScore={variant === "performing" ? 20 : 15}
            scoreColorCalculationMethod="index"
            scoreColorSetpoints={
              variant === "performing" ? { red: 4, yellow: 13, darkGreen: 17 } : { red: 3, yellow: 9, darkGreen: 13 }
            }
          />
        </Grid>
        <Grid xs={12} md={6} className={styles.scoreRight}>
          <div className={styles.scoreRightContainer}>
            <Glyph glyphType="materialize" glyph="emoji_objects" glyphClass={styles.scoreIcon} />
            <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.scoreText)}>
              {this.getScoreText()}
            </Typography>
          </div>
        </Grid>
        <Grid spacer spacerSize={reportVariant === "mobile" ? 2 : undefined} />
      </>
    )
  }

  private renderAnswers() {
    const { variant, answers, comparison } = this.props

    return (
      <>
        {answers.map((a, i) => {
          return this.renderAnswer(a, comparison?.[i])
        })}
        <Grid sm={12} className={styles.hrGrid}>
          <hr className={styles.hr} />
        </Grid>
        <Grid sm={12}>
          <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.footer)}>
            {this.txt(`${variant}_footer`)}
          </Typography>
        </Grid>
        <Grid spacer spacerSize={0.5} />
      </>
    )
  }

  private renderAnswer(answer: Answer, comparisonAnswer?: Answer) {
    const { reportVariant } = this.props

    return (
      <React.Fragment key={answer.question_id}>
        <Grid sm={12} className={styles.hrGrid}>
          <hr className={styles.hr} />
        </Grid>
        <Grid xs={reportVariant === "mobile" ? 12 : 9} className={styles.questionName}>
          <Typography useContrastColor className={reportSmallBodyClass}>
            {this.txt(answer.title)}
          </Typography>
        </Grid>
        <Grid xs={reportVariant === "mobile" ? 12 : 3} justifyContent="flex-end">
          <div className={styles.smallScoreMeter}>
            <ScoreMeter
              score={getAnswerPoints(answer)}
              scoreComparison={comparisonAnswer ? getAnswerPoints(comparisonAnswer) : undefined}
              scoreDescription={getAnswerTitle(answer, this.localization)}
              scoreDescriptionComparison={
                comparisonAnswer ? getAnswerTitle(comparisonAnswer, this.localization) : undefined
              }
              minScore={getAnswerMinPoints(answer)}
              maxScore={getAnswerMaxPoints(answer)}
              showBottomScoreArea
              bottomScoreType="answerOption"
              scoreColorCalculationMethod="value"
              scoreColorSetpoints={getScoreColorSetpointsForAnswer(answer)}
            />
          </div>
        </Grid>
      </React.Fragment>
    )
  }

  private getScore(isComparison?: boolean) {
    const { answers, comparison } = this.props

    return (isComparison ? comparison : answers)?.reduce((score: number, answer: Answer) => {
      return score + getAnswerPoints(answer)!
    }, 0)
  }

  private getScoreText() {
    const { variant } = this.props

    const score = this.getScore()

    if (!score) return ""

    if (variant === "surviving") {
      if (score >= 13) {
        return this.txt("surviving_13_15")
      } else if (score >= 9) {
        return this.txt("surviving_9_12")
      } else {
        return this.txt("surviving_3_8")
      }
    } else if (variant === "performing") {
      if (score >= 17) {
        return this.txt("performing_17_20")
      } else if (score >= 13) {
        return this.txt("performing_13_16")
      } else {
        return this.txt("performing_4_12")
      }
    } else if (variant === "thriving") {
      if (score >= 13) {
        return this.txt("thriving_13_15")
      } else if (score >= 9) {
        return this.txt("thriving_9_12")
      } else {
        return this.txt("thriving_3_8")
      }
    }
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  styles = stylesheet({
    containerOuter: {
      margin: reportVariant === "mobile" ? undefined : "2rem 2rem"
    },
    containerInnerDesktop: {
      borderStyle: "solid",
      borderColor: SurvivingPerformingThrivingContainer.theme.colors.hintsa.alabaster.fadeOut(0.5).toString(),
      margin: "-2rem",
      borderRadius: "1rem",
      padding: "2rem 3rem",
      boxShadow: "0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.2);",
      background: SurvivingPerformingThrivingContainer.theme.colors.hintsa.charcoal.toString()
    },
    questionName: {
      display: "flex",
      alignItems: "center",
      marginBottom: reportVariant === "mobile" ? csx.important("-1.5rem") : undefined
    },
    heading: {
      margin: reportVariant === "mobile" ? "0.5rem 0 0.5rem 0" : "0.5rem 0 2rem 0"
    },
    scoreText: {
      marginTop: "1rem"
    },
    scoreIcon: {
      fontSize: "2.5rem",
      marginRight: "0.5rem"
    },
    scoreLeft: {
      paddingRight: "1.5rem"
    },
    scoreRight: {
      display: "flex",
      alignItems: "center"
    },
    scoreRightContainer: {
      padding: "0.5rem 1.5rem",
      display: "flex",
      alignItems: "center",
      backgroundColor: SurvivingPerformingThrivingContainer.theme.colors.hintsa.granite.fade(0.3).toString(),
      borderRadius: "0.5rem"
    },
    hr: {
      marginTop: "1rem",
      backgroundColor: SurvivingPerformingThrivingContainer.theme.colors.hintsa.charcoal.toString(),
      opacity: 0.5,
      borderStyle: "dashed"
    },
    hrGrid: {
      width: "100%"
    },
    smallScoreMeter: {
      marginTop: "2rem",
      marginBottom: "-1rem"
    },
    footer: {
      color: csx.important(SurvivingPerformingThrivingContainer.theme.colors.hintsa.alabaster.toString())
    }
  })
}
