import { SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import Typography from "lib/ui/components/typography/Typography"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportBodyClass, reportMediumHeadingClass } from "../../WellbeingReport"
import SurvivingPerformingThrivingContainer from "./SurvivingPerformingThrivingContainer"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  reportVariant: ReportVariant
}

export default class ReportSectionSurvivingPerformingThriving extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionSurvivingPerformingThriving"]
  }

  get backgroundSrc() {
    return `${this.appConfig.imagesRoot}/wellbeing_report/thriving_dots.svg`
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    const { reportVariant, answer, comparison } = this.props

    const survivingAnswers = this.getAnswers("surviving", answer)
    const performingAnswers = this.getAnswers("performing", answer)
    const thrivingAnswers = this.getAnswers("thriving", answer)
    const survivingAnswersComparison = this.getAnswers("surviving", comparison)
    const performingAnswersComparison = this.getAnswers("performing", comparison)
    const thrivingAnswersComparison = this.getAnswers("thriving", comparison)

    if (!survivingAnswers && !performingAnswers && !thrivingAnswers) return null

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid spacer spacerSize={6} />

        <div className={styles.topContainer}>
          <img alt="CoBL" src={this.backgroundSrc} className={styles.image} />
        </div>
        <Grid xs={12} sm={6}>
          <Typography useContrastColor className={classNames(reportMediumHeadingClass)}>
            {this.txt("title")}
          </Typography>
          <Typography useContrastColor component="div" className={classNames(reportBodyClass)}>
            <Markdown content={this.txt("description")} />
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}></Grid>
        <Grid spacer spacerSize={6} />
        {/** render Surviving, Performing and Thriving sections if available and set correct amount of spacing */}
        {survivingAnswers && (
          <Grid xs={12}>
            <SurvivingPerformingThrivingContainer
              variant="surviving"
              reportVariant={reportVariant}
              answers={survivingAnswers}
              comparison={survivingAnswersComparison}
            />
          </Grid>
        )}
        {survivingAnswers && (performingAnswers || thrivingAnswers) && this.renderSpacer()}
        {performingAnswers && (
          <Grid xs={12}>
            <SurvivingPerformingThrivingContainer
              variant="performing"
              reportVariant={reportVariant}
              answers={performingAnswers}
              comparison={performingAnswersComparison}
            />
          </Grid>
        )}
        {performingAnswers && thrivingAnswers && this.renderSpacer()}
        {thrivingAnswers && (
          <Grid xs={12}>
            <SurvivingPerformingThrivingContainer
              variant="thriving"
              reportVariant={reportVariant}
              answers={thrivingAnswers}
              comparison={thrivingAnswersComparison}
            />
          </Grid>
        )}
        <Grid spacer spacerSize={8} />
      </>
    )
  }

  private renderSpacer() {
    const { reportVariant } = this.props

    return (
      <>
        {reportVariant === "pdf" && (
          <>
            <div className="hard-page-break" />
            <div className={styles.topContainer}>
              <img alt="CoBL" src={this.backgroundSrc} className={styles.image} />
            </div>
          </>
        )}
        <Grid spacer spacerSize={{ desktop: 5, mobile: 8, pdf: 24 }[reportVariant]} />
      </>
    )
  }

  private getAnswers(variant: "surviving" | "performing" | "thriving", answer: SurveyAnswerInstance | undefined) {
    const questionKeys: string[] = []

    if (!answer) return undefined

    switch (variant) {
      case "surviving":
        questionKeys.push("ww_burnout_physical", "ww_burnout_cognitive", "ww_burnout_emotional")
        break
      case "performing":
        questionKeys.push("ww_vitality", "ww_meaningfulness", "ww_autonomy", "ww_relatedness")
        break
      case "thriving":
        questionKeys.push("ww_enthusiastic", "ww_accomplishment", "ww_learning")
        break
    }

    const answers = answer.answers!.filter(q => questionKeys.includes(q.question_key!))
    return answers.length > 0 ? answers : undefined
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  styles = stylesheet({
    topContainer: {
      display: "flex",
      flexDirection: "column",
      position: "relative"
    },
    image: {
      position: "absolute",
      top: reportVariant === "mobile" ? undefined : "6rem",
      marginRight: reportVariant === "mobile" ? undefined : "-15rem",
      zIndex: -1
    },
    description: {
      position: "absolute"
    }
  })
}
