import { CssBaseline } from "@material-ui/core"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
import transitions from "@material-ui/core/styles/transitions"
import { PropsWithChildren, PureComponent } from "react"

import { Theme } from "core/modules/themes/Theme"

interface ThemeProps {
  theme: Theme
}

export default class ThemeProvider extends PureComponent<PropsWithChildren<ThemeProps>> {
  get theme() {
    const { theme } = this.props

    return createTheme({
      palette: {
        primary: {
          main: theme.colors.primary.toString(),
          contrastText: theme.colors.primaryContrast.toString()
        },
        secondary: {
          main: theme.colors.secondary.toString(),
          contrastText: theme.colors.secondaryContrast.toString()
        },
        text: {
          primary: theme.colors.fontColor.toString(),
          secondary: theme.colors.deEmphasizedColor.toString()
        }
      },

      spacing: 10,

      typography: {
        fontSize: parseInt(theme.font.fontSize),
        fontFamily: theme.font.fontFamily,
        h1: {
          fontSize: "5rem",
          marginBottom: "3.5rem"
        },
        h2: {
          fontSize: 64
        },
        h3: {
          fontSize: 36,
          fontWeight: 700,
          letterSpacing: -0.4,
          lineHeight: "34.6px"
        },
        h4: {
          fontSize: 24
        },
        h5: {
          fontSize: 24
        },
        h6: {
          fontSize: 24
        },
        subtitle1: {},
        body1: {
          fontSize: "1.125rem",
          letterSpacing: -0.22,
          fontWeight: 400,
          lineHeight: "1.5rem",
          color: theme.font.fontSize
        },
        body2: {},
        caption: {
          fontSize: 12,
          fontWeight: 500,
          lineHeight: "18.6px"
        }
      },

      overrides: {
        MuiCssBaseline: {
          "@global": {
            body: {
              backgroundColor: theme.colors.windowColor.toString(),
              color: theme.colors.fontColor.toString(),
              display: "flex",
              flexDirection: "column",
              fontFamily: theme.font.fontFamily,
              fontSize: theme.font.fontSize,
              minHeight: "100%"
            },
            html: {
              height: "100%",
              fontSize: theme.font.fontSize
            },
            "#webapp_container": {
              display: "flex",
              flexDirection: "column",
              height: "100%"
            }
          }
        },
        MuiFormLabel: {
          root: {
            color: theme.colors.primary.toString(),
            fontSize: `${theme.font.fontSize} !important`,
            fontWeight: "normal",
            transform: "translate(0, 1.5px) !important"
          }
        },
        MuiInputBase: {
          root: {
            backgroundColor: theme.colors.inputBackgroundColor.toString(),
            border: `1px solid ${theme.colors.inputBorderColor.toString()}`,
            borderRadius: "0.5rem",
            fontSize: theme.font.fontSize,
            transition: transitions.create(["border-color", "box-shadow"]),

            "label + &": { marginTop: "2rem !important" }
          },
          input: {
            padding: "0.5rem 1rem",

            "&::placeholder": { fontStyle: "italic" }
          }
        }
      }
    })
  }

  render() {
    return (
      <MuiThemeProvider theme={this.theme}>
        <CssBaseline />
        {this.props.children}
      </MuiThemeProvider>
    )
  }
}
