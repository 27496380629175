import { Answer, Question } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../base/LogicComponent"
import RadioQuestionView from "./RadioQuestionView"

interface RadioQuestionProps extends LogicComponentProps {
  question: Question
  answer?: Answer
  disabled?: boolean
  className?: string
  onOptionSelected: (questionId: string, optionId: string) => void
}

export default class RadioQuestion extends LogicComponent<RadioQuestionProps> {
  render() {
    super.render()

    const { answer, disabled, onOptionSelected, question, className } = this.props
    const selected = answer?.options?.[0]?.id?.toString()
    const options = question.options.map(option => ({
      label: this.txt(option.title),
      value: option.id!.toString()
    }))

    return (
      <RadioQuestionView
        options={options}
        value={selected}
        disabled={disabled}
        className={className}
        onChange={value => onOptionSelected(question.id!, value)}
      />
    )
  }
}
