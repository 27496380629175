import moment from "moment"

import { BaseModuleWithAppName } from "core/controller/Module"

export interface Progress {
  id: string
  name?: string
  scope?: string
  startTime?: moment.Moment
  progress?: number
  totalProgress?: number
  canceled?: boolean
  completed?: boolean
  failed?: boolean
  details?: any
  error?: any
  result?: any
  status?: string | null
  updated_at?: Date | null
  task_id?: string
  onResolve?: (value?: Progress | PromiseLike<Progress>) => void
  onReject?: (progress: Progress) => void
  onUpdate?: (progress: Progress) => void
}

export interface ProgressManager {
  startProgress(progress: Omit<Progress, "id">): Progress
  completeProgress(id?: string): void
  cancelProgress(id?: string): void
  updateProgress(progress: Progress): void
  failProgress(id?: string, error?: any): void
  findProgressById(id: string): Progress | undefined
  findProgressByName(name: string): Progress[]
  findProgressByScope(scope: string): Progress[]
}

export class ProgressManagerModule extends BaseModuleWithAppName implements ProgressManager {
  private inProgress: { [id: string]: Progress } = {}
  private done: { [id: string]: Progress } = {}

  private uniqueProgressId = 0

  get moduleName() {
    return "ProgressManager"
  }

  get nextProgressId() {
    return ++this.uniqueProgressId
  }

  startProgress(newProgress: Omit<Progress, "id">) {
    const progressId = this.nextProgressId
    const progress: Progress = { ...newProgress, id: progressId.toString() }

    this.logger.debug("Starting progress", { id: progress.id, progress })
    this.inProgress[progress.id] = progress

    return progress
  }

  completeProgress(id?: string) {
    if (!id) return

    if (this.done[id]) {
      this.logger.warning("Tried to complete done progress", id)
      return
    }

    const progressObject = this.inProgress[id]
    if (!progressObject) {
      this.logger.warning("Tried to complete progress that doesn't exist", id)
      return
    }

    this.logger.debug("Completing progress", id)
    delete this.inProgress[id]
    progressObject.completed = true
    this.done[id] = progressObject

    progressObject.onResolve?.(progressObject)
  }

  updateProgress(progress: Progress) {
    if (!progress) return

    if (this.done[progress.id]) {
      this.logger.warning("Tried to update done progress", progress)
      return
    }

    if (!this.inProgress[progress.id]) {
      this.logger.warning("Tried to update progress that doesn't exist", progress)
      return
    }

    this.logger.debug("Updating progress", { id: progress.id, progress })
    this.inProgress[progress.id] = Object.assign({}, progress)
  }

  cancelProgress(id?: string) {
    if (!id) return

    if (this.done[id]) {
      this.logger.warning("Tried to cancel done progress", id)
      return
    }

    const progressObject = this.inProgress[id]
    if (!progressObject) {
      this.logger.warning("Tried to cancel progress that doesn't exist", id)
      return
    }

    this.logger.debug("Canceling progress", id)
    delete this.inProgress[id]
    progressObject.canceled = true
    this.done[id] = progressObject

    progressObject.onReject?.(progressObject)
  }

  failProgress(id?: string, error?: any) {
    if (!id) return

    if (this.done[id]) {
      this.logger.warning("Tried to fail done progress", id)
      return
    }

    const progressObject = this.inProgress[id]
    if (!progressObject) {
      this.logger.warning("Tried to fail progress that doesn't exist", id)
      return
    }

    this.logger.debug("Failing progress", id)
    delete this.inProgress[id]
    progressObject.failed = true
    progressObject.error = error || progressObject.error
    this.done[id] = progressObject

    progressObject.onReject?.(progressObject)
  }

  findProgressById(id: string, includeDone?: boolean) {
    let ret = this.inProgress[id]

    if (!ret && includeDone) ret = this.done[id]

    return ret
  }

  findProgressByName(name: string, includeDone?: boolean) {
    const ret = Object.values(this.inProgress).filter(progress => progress?.name === name)

    if (includeDone) {
      ret.push(...Object.values(this.done))
    }

    return ret
  }

  findProgressByScope(scope: string, includeDone?: boolean) {
    const ret = Object.values(this.inProgress).filter(progress => progress?.scope === scope)

    if (includeDone) {
      ret.push(...Object.values(this.done))
    }

    return ret
  }
}
