import { Logger } from "core/modules/logging/Logger"
import { ModelManagerInternal } from "./ModelManager"

/**
 * Container class for managing documents in external DocumentContainer.
 */
export class ContainerManager {
  protected modelManager?: ModelManagerInternal
  protected logger?: Logger
  protected _dirty: boolean = false

  private deleted = false

  get dirty(): boolean {
    return this._dirty
  }

  /**
   * Called after a batch of operations has completed. After dirty flag is reset,
   * any new mutation will lead to data structures being cloned.
   */
  resetDirtyFlag() {
    this._dirty = false
  }

  /**
   * Should be called when disassociated from model manager.
   */
  invalidate() {
    this.deleted = true
  }

  protected verifyStateRead() {
    if (this.deleted) throw new Error("Attempting to read from stale container.")
  }

  protected verifyStateModify() {
    if (this.deleted) throw new Error("Attempting to modify stale container.")

    if (!this.modelManager?.isWithinTransaction) throw new Error("Attempting to modify container outside transaction.")
  }
}
