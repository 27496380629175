import { BaseModuleWithAppName } from "core/controller/Module"
import { baseLocalizationConfig } from "core/modules/config/localizationConfig"
import { LocalizationState, Model } from "core/modules/state/model/Model"

export interface InitialState {
  getInitialState(): Model
}

/**
 * The initial redux state
 */
export class InitialStateModule extends BaseModuleWithAppName implements InitialState {
  get moduleName() {
    return "InitialState"
  }

  get setupPriority() {
    return -2000
  }

  setup() {
    const localizationState: Partial<LocalizationState> = {
      __type: "LocalizationState",
      language: baseLocalizationConfig.defaultLanguage
    }

    this.coreActions.setDefaultDocumentLocal(localizationState)
  }

  getInitialState(): Model {
    return {
      modifyCount: 0,
      idCounter: -2, // -1 is the default document. Start from -2.
      documents: {
        LocalizationState: { __type: <string>"LocalizationState" }
      },
      views: {}
    }
  }
}
