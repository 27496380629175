import { Theme, type WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as csx from "csx"
import type { ReactNode } from "react"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import { RadioOption } from "lib/ui/components/form/RadioField"

import { ViewComponent } from "../../base/ViewComponent"

const styles = ({ palette, transitions }: Theme) =>
  createStyles({
    numberQuestionContainer: {},
    input: {
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.011,
      zIndex: 100,
      width: "100%",
      height: "100%",
      cursor: "pointer",
      background: csx.important(NumberQuestionView.theme.colors.buttonColor.lighten("10%").toString()),
      "&:checked + label": {
        backgroundColor: csx.important(NumberQuestionView.theme.colors.secondary.toString()),
        color: csx.important(NumberQuestionView.theme.colors.windowColor.toString()),
        transition: "background-color 0.25s ease"
      }
    },
    labelFirst: {
      borderTopLeftRadius: "0.5rem",
      borderBottomLeftRadius: "0.5rem"
    },
    labelLast: {
      borderTopRightRadius: "0.5rem",
      borderBottomRightRadius: "0.5rem",
      borderWidth: csx.important("2px")
    },
    inputContainer: {
      flex: 1,
      position: "relative",
      textAlign: "center",
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: NumberQuestionView.theme.colors.veryFaintColor.fade(0.5).toString()
      }
    },
    label: {
      display: "flex",
      zIndex: 90,
      alignItems: "center",
      justifyContent: "center",
      padding: "1rem 0",
      borderStyle: "solid",
      borderWidth: "2px 0 2px 2px",
      margin: 0
    },
    labelContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1rem"
    },
    labelEnabled: {
      cursor: "pointer",
      borderColor: NumberQuestionView.theme.colors.baseGray.toString(),
      color: NumberQuestionView.theme.colors.secondary.toString()
    },
    labelDisabled: {
      borderColor: NumberQuestionView.theme.colors.deEmphasizedColor.toString(),
      color: NumberQuestionView.theme.colors.deEmphasizedColor.toString()
    },
    container: {
      display: "flex",
      position: "relative"
    },
    childContainer: {
      position: "relative"
    }
  })

interface NumberQuestionViewProps extends ViewComponentProps, WithStyles<typeof styles> {
  name: string
  radioQuestionOptions: RadioOption[]
  value?: string
  disabled?: boolean
  questionKey?: string
  labelStart?: string
  labelEnd?: string
  onChange: (value: string) => void
}

class NumberQuestionView extends ViewComponent<NumberQuestionViewProps> {
  get componentName() {
    return ["survey", "ui", "questions", "EnpsQuestionView"]
  }

  render() {
    super.render()

    const { classes } = this.props

    return (
      <div className={classes.numberQuestionContainer}>
        <div className={classes.container}>{this.renderRadioButtons()}</div>
        {this.renderButtonLabels()}
      </div>
    )
  }
  private renderButtonLabels() {
    const { classes, labelStart, labelEnd } = this.props

    if (!labelStart || !labelEnd) return null

    return (
      <div className={classes.labelContainer}>
        <span>{labelStart}</span>
        <span>{labelEnd}</span>
      </div>
    )
  }

  private renderRadioButtons() {
    const { radioQuestionOptions, disabled, classes, name, value, onChange } = this.props

    const results: ReactNode[] = []
    for (let i = 0; i < radioQuestionOptions.length; i++) {
      const radioQuestionOption = radioQuestionOptions[i]
      results.push(
        <div key={i} className={classes.inputContainer}>
          <div className={classes.childContainer}>
            <input
              disabled={disabled}
              className={classes.input}
              checked={radioQuestionOption.value?.toString() === value?.toString()}
              id={`${name}_${i}`}
              type="radio"
              name={name}
              value={radioQuestionOption.value}
              onChange={() => onChange(radioQuestionOption.value)}
            />
            <label
              className={classNames(
                classes.label,
                disabled ? classes.labelDisabled : classes.labelEnabled,
                i === 0 && classes.labelFirst,
                i === radioQuestionOptions.length - 1 && classes.labelLast
              )}
              htmlFor={`${name}_${i}`}
            >
              {radioQuestionOption.label}
            </label>
          </div>
        </div>
      )
    }
    return results
  }
}

export default withStyles(styles)(NumberQuestionView)
