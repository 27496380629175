import { Logger } from "core/modules/logging/Logger"

import { ContainerManager } from "./ContainerManager"
import { Doc, DocumentContainer } from "./Model"
import { ModelManagerInternal } from "./ModelManager"

/**
 * Container class for managing documents in external DocumentContainer.
 */
export class DocumentContainerManager<T extends Doc> extends ContainerManager {
  constructor(
    protected container: DocumentContainer<T>,
    protected modelManager: ModelManagerInternal,
    protected logger: Logger
  ) {
    super()
  }

  get all() {
    this.verifyStateRead()

    return Object.values(this.container.dictionary ?? {})
  }

  /**
   * Get object from container with key
   */
  get(key: string): T | undefined {
    this.verifyStateRead()

    return this.container.dictionary?.[key]
  }

  set(document: T): void {
    this.setDirtyFlag()
    this.addDocumentToDictionary(document)
  }

  removeDocument(document: T | string) {
    const documentId = typeof document === "string" ? document : document.id
    if (!documentId) return

    this.verifyStateModify()
    this.setDirtyFlag()
    this.removeItemFromDictionary(documentId)
  }

  initialize(type: string): void {
    this.verifyStateModify()
    this.container.__type = type
    this.container.dictionary = {}
    this._dirty = false
  }

  private removeItemFromDictionary(key: string): void {
    if (this.container.dictionary && key in this.container.dictionary) {
      delete this.container.dictionary[key]
    }
  }

  private addDocumentToDictionary(document: T, checkForDuplicates: boolean = false): boolean {
    this.container.dictionary ??= {}

    const key: string = document.id!
    const exists = key in this.container.dictionary

    if (checkForDuplicates && exists) {
      throw new Error("Duplicate key. Key: ${key}, container: ${this._container.type} ")
    }

    this.container.dictionary[key] = document

    return exists
  }

  /**
   * In case of any mutation, the unerlying data structures will be cloned and dirty flag set. We do not wish to
   * clone always due to performance cost.
   */
  private setDirtyFlag() {
    if (this.dirty) return

    this.logger.trace("Dirty flag set. Cloning document container", this.container.__type)

    this.container = this.modelManager.cloneDocumentContainer(this.container.__type)
    this.container.dictionary = Object.assign({}, this.container.dictionary)

    this._dirty = true
  }
}
