import { Logger, logger } from "core/modules/logging/Logger"
import { Component, type PropsWithChildren, type ReactNode } from "react"

import { AppImpl } from "core/controller/App"
import { Module } from "core/controller/Module"
import { Localization } from "core/modules/localization/Localization"
import { Theme } from "core/modules/themes/Theme"
import { isMobile } from "lib/ui/compatibility/Browsers"

export interface ComponentInterface extends Module {
  defaultDependencies: string[]
  dependencies: string[]

  /**
   * Name of the application this component belongs to. Not necessarily the running app.
   */
  appName: string
}

export interface BaseComponentProps extends PropsWithChildren {
  /**
   * Override language for one component
   */
  language?: string

  /**
   * Override theme for one component
   */
  theme?: Theme
}

export interface BaseComponentState {
  language?: string
  theme?: Theme
}

export abstract class BaseComponent<P extends BaseComponentProps, S extends BaseComponentState | void = {}>
  extends Component<P, S>
  implements ComponentInterface
{
  static theme: Theme

  isMobile = isMobile()

  get componentName(): string[] {
    return []
  }

  abstract get appName(): string

  get defaultDependencies() {
    return ["Localization"]
  }

  get dependencies(): string[] {
    return []
  }

  protected get theme() {
    return BaseComponent.theme
  }

  declare localization: Localization

  protected firstRender: boolean | undefined
  private _logger: Logger | undefined
  private moduleManagerId: string
  private registered: boolean

  constructor(props: P) {
    super(props)

    this.moduleManagerId = AppImpl.theApp.moduleManager.registerComponent(this)
    this.registered = true
  }

  get logger(): Logger {
    return (this._logger ??= new logger(this.constructor.name))
  }

  render(): ReactNode {
    return undefined
  }

  /**
   * Get translation
   * @param key Usage
   *      key(keyInThisComponent, params?)
   *      key(namespac1Name, namespace2Name, ..., key, params?)
   */
  txt(...key): string {
    // Add current component namespace if key is just a string
    if (typeof key[0] === "string" && (key.length === 1 || (key.length === 2 && typeof key[1] === "object"))) {
      key = [this.appName, "components", ...this.componentName, ...key]
    }

    return this.localization.txt(...key)
  }

  updateState(update: (state: S) => void, callback?: Function): void {
    this.setState(
      prevState => {
        const newState = Object.assign({}, prevState)
        update(newState)
        return newState
      },
      () => {
        if (callback) callback()
      }
    )
  }

  /**
   * Create a state setter for a specific key.
   *
   * @example
   *    const setName = this.stateSetter("name")
   *    <Input onChange={setName} />
   */
  stateSetter =
    <K extends keyof S>(key: K) =>
    (value: S[K]) => {
      this.updateState(state => (state[key] = value))
    }

  componentDidUpdate() {
    this.firstRender = false
  }

  UNSAFE_componentWillMount() {
    if (!this.registered) {
      this.moduleManagerId = AppImpl.theApp.moduleManager.registerComponent(this)
      this.registered = true
    }
  }

  componentWillUnmount() {
    AppImpl.theApp.moduleManager.unregisterComponent(this.moduleManagerId)
  }

  shouldComponentUpdate(_nextProps: P, _nextState: S): boolean {
    return true
  }
}
