import { SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import Circle from "lib/ui/components/symbols/Circle"
import Typography from "lib/ui/components/typography/Typography"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import {
  ReportVariant,
  reportBodyClass,
  reportLargeBodyClass,
  reportMediumHeadingClass,
  reportSmallBodyClass
} from "../../WellbeingReport"
import ReportBigPictureLadder from "./ReportBigPictureLadder"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  reportVariant: ReportVariant
}

export default class ReportSectionBigPicture extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionBigPicture"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.variant)
  }

  render() {
    const { answer, comparison, reportVariant } = this.props

    const lsLadderPoints = answer.answers?.find(answer => answer.question_key === "ls_ladder")?.options?.[0]?.points
    const wpLadderPoints = answer.answers?.find(answer => answer.question_key === "wp_ladder")?.options?.[0]?.points
    const lsLadderPointsComparison = comparison?.answers?.find(answer => answer.question_key === "ls_ladder")
      ?.options?.[0]?.points
    const wpLadderPointsComparison = comparison?.answers?.find(answer => answer.question_key === "wp_ladder")
      ?.options?.[0]?.points

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid container alignItems="center">
          <Grid spacer spacerSize={6} />
          <Grid sm={12}>
            <Typography useContrastColor className={classNames(reportMediumHeadingClass, styles.heading)}>
              {this.txt("title")}
            </Typography>
          </Grid>
          <Grid sm={12} lg={reportVariant === "pdf" ? 12 : 6} className={styles.ladderGrid}>
            {this.renderLadders(lsLadderPoints, wpLadderPoints, lsLadderPointsComparison, wpLadderPointsComparison)}
          </Grid>
          {reportVariant === "pdf" && <Grid spacer spacerSize={12} />}
          <Grid sm={12} lg={reportVariant === "pdf" ? 12 : 6}>
            {this.renderDescription(lsLadderPoints! >= 9 && wpLadderPoints! >= 9)}
          </Grid>
        </Grid>
      </>
    )
  }

  private renderDescription(isHighScore: boolean) {
    return (
      <div className={styles.descriptionContainer}>
        <Typography useContrastColor className={classNames(reportLargeBodyClass, styles.descriptionLargeBody)}>
          {this.txt("subtitle")}
        </Typography>
        <Typography component="div" useContrastColor className={classNames(reportBodyClass, styles.descriptionBody)}>
          <Markdown content={isHighScore ? this.txt("description_high_score") : this.txt("description_standard")} />
        </Typography>
      </div>
    )
  }

  private renderLadders(
    lsLadderPoints: number | undefined,
    wpLadderPoints: number | undefined,
    lsLadderPointsComparison: number | undefined,
    wpLadderPointsComparison: number | undefined
  ) {
    const { answer } = this.props

    const numberOfSteps = 10

    return (
      <div>
        <div className={styles.ladderContainer}>
          <ReportBigPictureLadder
            name={this.txt("life_satisfaction")}
            score={lsLadderPoints}
            comparisonScore={lsLadderPointsComparison}
            steps={numberOfSteps}
          />
          <ReportBigPictureLadder
            name={this.txt("work_performance")}
            score={wpLadderPoints}
            comparisonScore={wpLadderPointsComparison}
            steps={numberOfSteps}
          />
        </div>
        <div className={styles.resultsLabel}>
          <Circle diameter="1.5rem" className={styles.circle} color={this.theme.colors.hintsa.alabaster} />
          <Typography variant="body" useContrastColor className={reportSmallBodyClass}>
            {this.txt("previous_score")}
          </Typography>
        </div>
      </div>
    )
  }
}

let styles

const setupStyles = (variant: ReportVariant) => {
  styles = stylesheet({
    descriptionContainer: {
      display: "flex",
      flexDirection: "column"
    },
    ladderGrid: {
      display: "flex",
      justifyContent: "center",
      width: "100%"
    },
    ladderContainer: {
      marginTop: variant === "mobile" ? "1rem" : "4rem",
      display: "flex",
      scale: variant === "mobile" ? "80%" : undefined
    },
    heading: {
      marginBottom: "2rem"
    },
    descriptionLargeBody: {
      marginTop: "2rem",
      marginBottom: "0.5rem"
    },
    descriptionBody: {
      margin: 0
    },
    legend: {
      display: "flex"
    },
    resultsLabel: {
      display: "flex",
      position: "relative",
      alignItems: "center"
    },
    circle: {
      marginRight: "0.5rem"
    }
  })
}
