import deepMerge from "deepmerge"
import EventEmitter from "wolfy87-eventemitter"

import { BaseModuleWithAppName } from "core/controller/Module"
import { DocumentConverter } from "core/modules/state/documentconverter/DocumentConverter"
import { InitialState } from "core/modules/state/initialstate/InitialState"

import { ViewParameters } from "../../actions/CoreActions"
import { CoreApi } from "../../api/CoreApi"
import { DocumentContainerManager } from "./DocumentContainerManager"
import { Doc, DocumentContainer, Link, Model, ViewContainer, defaultDocumentId } from "./Model"
import { ViewContainerManager } from "./ViewContainerManager"

export interface ModelManager {
  onImportDocument: Array<(document: Doc, previousDocument?: Doc) => void>
  documentTypes: string[]

  events: EventEmitter

  /**
   * Returns document with an identifier
   */
  getDocument<T extends Doc>(id: string, type: string): T | undefined

  /**
   * Returns document with an identifier
   */
  getDocuments<T extends Doc>(type: string): T[]

  /**
   * Returns the default document for this collection
   */
  getDefaultDocument<T extends Doc>(type: string): T | undefined

  /**
   * Returns view manager
   */
  getView<T extends Doc>(viewName: string | ViewContainer): ViewContainerManager<T>

  /**
   * Returns names of all view container managers
   */
  getViewNames(): string[]

  /**
   * Returns an incremented identifier unique for this model manager and this program run
   */
  getUniqueId(): string

  /**
   * Returns a generated id for document
   */
  getNextDocumentId(): string
}

export interface ModelManagerInternal extends ModelManager {
  model: Model

  /**
   * Starts a transaction during which the model can be updated. During a transaction only one
   * clone of each immutable collection will be made.
   */
  startTransaction(model?: Model): void

  /**
   * Ends the tranaction. No updates are possible before opening a new transaction.
   */
  endTransaction(): void

  /**
   * Returns whether model manager is within transaction
   */
  isWithinTransaction(): boolean

  /**
   * Set object's data to model. Returns the final document.
   */
  setDocument<T extends Doc>(document: T): T

  /**
   * Set object's data to model. Returns the final document.
   */
  setDefaultDocument<T extends Doc>(document: T): T

  /**
   * Remove a document from the model. Requires an ongoing transaction.
   */
  removeDocument<T extends Doc>(document: T | string, type?: string): void

  initializeView<T extends Doc>(viewName: string, documentType: string): ViewContainerManager<T>

  unloadView(viewName: string): void

  /**
   * Mark view as invalid. It will be refreshed when next accessed.
   */
  invalidateView(viewName: string): void

  importView<T extends Doc>(
    viewData: T[] | { [itemsKey: string]: T[] },
    parameters: ViewParameters,
    viewDataRaw: any,
    api: CoreApi
  ): void

  /**
   * Imports a document from external data.
   */
  importDocument<T extends Doc>(document: T, documentType: string, inline?: boolean): T

  /**
   * Revert to model's initial state. Requires an ongoing transaction.
   */
  revertToInitialState(): any

  /**
   * Clones the given view in model and returns the cloned view.
   */
  cloneViewContainer(viewName: string): ViewContainer

  /**
   * Clones the given document container in model and returns the cloned container.
   */
  cloneDocumentContainer<T extends Doc>(documentType: string): DocumentContainer<T>
}

export class ModelManagerModule extends BaseModuleWithAppName implements ModelManagerInternal {
  onImportDocument = <Array<(document: Doc, previousDocument?: Doc) => void>>[]

  declare documentConverter: DocumentConverter
  declare initialStates: InitialState[]

  private initialStateCombined: any
  private _model?: Model
  private documentContainerManagers: { [id: string]: DocumentContainerManager<any> } = {}
  private viewContainerManagers: { [id: string]: ViewContainerManager<any> } = {}
  private transaction: boolean = false
  private uniqueId = 0

  events = new EventEmitter()

  get model(): Model {
    return this._model!
  }

  get moduleName() {
    return "ModelManager"
  }

  get dependencies() {
    return ["DocumentConverter", "InitialState"]
  }

  get setupPriority(): number {
    return 10
  }

  get documentTypes() {
    return Object.keys(this.documentContainerManagers)
  }

  setup() {
    this.logger.startGroup("Setup model manager", false)

    this.combineInitialStates()
    this.startTransaction()
    this.revertToInitialState()
    this.endTransaction()

    this.logger.endGroup()
  }

  getDocument<T extends Doc>(id: string, type: string): T | undefined {
    const container = this.documentContainerManagers[type]
    if (!container) return

    const doc = container.get(id)
    if (!doc) return

    return doc
  }

  getDocuments<T extends Doc>(type: string): T[] {
    return this.documentContainerManagers[type]?.all ?? []
  }

  getDefaultDocument<T extends Doc>(type: string): T | undefined {
    return this.getDocument<T>(defaultDocumentId, type)
  }

  getView<T extends Doc>(view: string | ViewContainer): ViewContainerManager<T> {
    if (!view) throw new Error("No view name provided when getting a view")

    const viewName = typeof view === "string" ? view : view.viewName

    return this.viewContainerManagers[viewName] || new ViewContainerManager<T>(undefined, this, this.logger)
  }

  getViewNames() {
    return Object.keys(this.viewContainerManagers)
  }

  getUniqueId(): string {
    this.uniqueId++
    return this.uniqueId.toString()
  }

  getNextDocumentId() {
    if (!this.transaction) throw new Error("Attempted to get next document id outside transaction.")

    this.model.idCounter--
    return this.model.idCounter.toString()
  }

  startTransaction(model?: Model) {
    if (this.transaction) throw new Error("Unexpected start of transaction.")

    this.logger.startGroup("Model manager transaction", false)
    this.transaction = true

    if (model === undefined) {
      this.logger.debug("Undefined model provided. Reverting to initial state")
      this.revertToInitialState()
    } else {
      this.assignModel(model)
    }
  }

  endTransaction(): void {
    if (!this.transaction) return

    // Clear all dirty flags in containers.
    if (this.documentContainerManagers) {
      for (const key of Object.keys(this.documentContainerManagers)) {
        const containerHelper = this.documentContainerManagers[key]
        containerHelper.resetDirtyFlag()
      }
    }

    // Clear all dirty flags in containers.
    if (this.viewContainerManagers) {
      for (const key of Object.keys(this.viewContainerManagers)) {
        const containerHelper = this.viewContainerManagers[key]
        containerHelper.resetDirtyFlag()
      }
    }

    this.transaction = false
    this.logger.endGroup()

    this.events.emit("transactionEnded")
  }

  isWithinTransaction(): boolean {
    return this.transaction
  }

  setDocument<T extends Doc>(document: T): T {
    if (!this.transaction) throw new Error("Operations on data model are only possible during transaction.")
    if (!document.__type) this.logger.errorAndThrow("Document is missing type ", document)
    if (!document.id) this.logger.errorAndThrow("Document is missing identifier ", document)

    // Find out if the document exists
    const container = this.getDocumentContainer<T>(document.__type)

    const existingDocument = container.get(document.id)

    if (existingDocument) {
      // There is an existing document, merge the new document with it
      document = Object.assign({}, existingDocument, document)
      // Convert null values after assignment to enable removal of values
      this.convertNullValues(document)
    } else {
      document = Object.assign({}, document)
      // this is a new document. Store it into container.
      this.convertNullValues(document)
    }

    Object.freeze(document)
    container.set(document)

    return document
  }

  setDefaultDocument<T extends Doc>(document: T): T {
    document.id = defaultDocumentId

    return this.setDocument<T>(document)
  }

  importDocument<T extends Doc>(document: T, documentType: string, inline?: boolean) {
    if (!this.transaction) throw new Error("Operations on data model are only possible during transaction.")

    const previousDoc = this.onImportDocument.length > 0 ? this.getDocument<T>(document.id, documentType) : undefined

    // DocumentConverter will import given document and all related documents.
    const doc = this.documentConverter.importDocument<T>(document, documentType, inline)

    for (const handler of this.onImportDocument) {
      handler(doc, previousDoc)
    }

    return doc
  }

  removeDocument<T extends Doc>(document: T | string, type?: string) {
    if (!this.transaction) throw new Error("Operations on data model are only possible during transaction.")

    if (typeof document !== "string") type = document.__type
    if (!type) return

    this.documentContainerManagers[type].removeDocument(document)
  }

  revertToInitialState(): any {
    if (!this.transaction) throw new Error("Operations on data model are only possible during transaction.")

    const newState = Object.assign({}, this.initialStateCombined)
    this._model = newState

    this.createContainers(true)

    return newState
  }

  unloadView(viewName: string) {
    if (!this.transaction) {
      this.logger.errorAndThrow(
        "Operations on data model are only possible during transaction. Cannot unload view,",
        viewName
      )
    }

    delete this.model.views[viewName]
    delete this.viewContainerManagers[viewName]
  }

  invalidateView(viewName: string) {
    if (!this.transaction) {
      this.logger.errorAndThrow(
        "Operations on data model are only possible during transaction. Cannot invalidate view,",
        viewName
      )
    }

    this.viewContainerManagers[viewName]?.markAsInvalid()
  }

  initializeView<T extends Doc>(viewName: string, documentType: string): ViewContainerManager<T> {
    if (!this.transaction) {
      this.logger.errorAndThrow(
        "Operations on data model are only possible during transaction. Cannot initialize view.",
        viewName
      )
    }

    const view = this.model.views[viewName]
    if (!view) {
      this.model.views[viewName] = {
        documentType,
        modifyCount: 0,
        array: [],
        viewName
      }

      this.viewContainerManagers[viewName] = new ViewContainerManager(this.model.views[viewName], this, this.logger)
    }

    const viewContainerManager = this.viewContainerManagers[viewName]
    viewContainerManager.initialize()

    return viewContainerManager
  }

  importView<T extends Doc>(
    viewData: T[] | { [itemsKey: string]: T[] },
    parameters: ViewParameters,
    viewDataRaw: any,
    api: CoreApi
  ) {
    if (!this.transaction) this.logger.errorAndThrow("Operations on data model are only possible during transaction.")
    if (!parameters.type) this.logger.errorAndThrow("View name is missing from parameters.", parameters)

    // DocumentConverter will import given document and all related documents.
    this.documentConverter.importView<T>(parameters.type!, viewData, parameters, viewDataRaw, (doc, previousDoc) => {
      for (const handler of this.onImportDocument) {
        handler(doc, previousDoc)
      }
    })
    this.viewContainerManagers[parameters.type!].api = api
  }

  cloneViewContainer(viewName: string): ViewContainer {
    return (this.model.views[viewName] = {
      ...this.model.views[viewName],
      modifyCount: this.model.views[viewName].modifyCount + 1
    })
  }

  cloneDocumentContainer<T extends Doc>(documentType: string): DocumentContainer<T> {
    return (this.model.documents[documentType] = {
      ...this.model.documents[documentType],
      modifyCount: this.model.documents[documentType].modifyCount + 1
    })
  }

  // Todo: compare with initializeView and make logic and function names similar
  private getDocumentContainer<T extends Doc>(documentType: string): DocumentContainerManager<T> {
    let container = this.documentContainerManagers[documentType]
    if (!container) {
      if (!this.model.documents[documentType]) {
        this.logger.debug("Creating container for document", documentType)
        this.model.documents[documentType] = {
          __type: documentType,
          dictionary: {}
        }
      }

      this.logger.debug("Creating container manager for document", documentType)
      container = new DocumentContainerManager(this.model.documents[documentType], this, this.logger)
      this.documentContainerManagers[documentType] = container
    }

    return container
  }

  /**
   * Combine all initial states from dependencies and merge them into one initial state
   */
  private combineInitialStates() {
    this.logger.debug("Combining initial states", this.initialStates)

    const orderedInitialStates: any[] = []
    for (const initialState of this.initialStates) {
      const initialStateModel = initialState.getInitialState()

      if (initialStateModel.documents) {
        for (const key of Object.keys(initialStateModel.documents)) {
          if (!initialStateModel.documents[key].modifyCount) initialStateModel.documents[key].modifyCount = 0
        }
      }

      orderedInitialStates.push(initialStateModel)
    }

    this.initialStateCombined = deepMerge.all(orderedInitialStates, {
      clone: true
    })

    this.logger.debug("Combined final initial state", this.initialStateCombined)
  }

  private assignModel(model: Model) {
    this._model = {
      ...model,
      documents: { ...model.documents },
      views: { ...model.views },
      modifyCount: model.modifyCount + 1
    }
  }

  private createContainers(initialize: boolean) {
    this.logger.startGroup("Creating document and view containers", false)

    if (this.documentContainerManagers) {
      for (const key of Object.keys(this.documentContainerManagers)) {
        this.documentContainerManagers[key].invalidate()
      }
    }

    this.documentContainerManagers = {}

    for (const key of Object.keys(this.model.documents)) {
      this.logger.trace("Creating doucment container", key)
      const containerHelper = new DocumentContainerManager(this.model.documents[key], this, this.logger)

      if (initialize) containerHelper.initialize(<string>key)

      this.documentContainerManagers[key] = containerHelper
    }

    if (this.viewContainerManagers) {
      for (const key of Object.keys(this.viewContainerManagers)) {
        this.viewContainerManagers[key].invalidate()
      }
    }

    this.viewContainerManagers = {}

    for (const key of Object.keys(this.model.views)) {
      this.logger.trace("Creating view container", key)
      const containerManager = new ViewContainerManager(this.model.views[key], this, this.logger)

      if (initialize) containerManager.initialize()

      this.viewContainerManagers[key] = containerManager
    }

    this.logger.endGroup()
  }

  private convertNullValues(document: any) {
    for (const key in document) {
      if (document.hasOwnProperty(key)) {
        const property = document[key]

        if (property === null) {
          delete document[key]
        } else if (typeof property === "object") this.convertNullValues(property)
      }
    }
  }
}
