import type { ViewParameters as CoreViewParameters } from "core/modules/actions/CoreActions"
import type { RestApiCallParameters } from "core/modules/api/CoreApi"
import type { Localization } from "core/modules/localization/Localization"
import type { Doc, SurveyUserDetails } from "core/modules/state/model/Model"
import type { ModelManager } from "core/modules/state/model/ModelManager"
import type { ViewContainerManager } from "core/modules/state/model/ViewContainerManager"
import type { Store } from "core/modules/store/Store"

import { BaseModule } from "../../controller/Module"
import { SurveysApi } from "../api/SurveysApi"

export interface ViewParameters extends CoreViewParameters {
  page?: number
  documentsPerPage?: number
  [id: string]: any
}

export interface SurveyActions {
  getDocument<T extends Doc>(id: string | T, type: string): Promise<T | undefined>
  updateDocument<T extends Doc>(document: T, parameters?: RestApiCallParameters<Partial<T>>): Promise<T | undefined>
  createDocument<T extends Doc>(
    document: Partial<T>,
    parameters?: RestApiCallParameters<Partial<T>>
  ): Promise<T | undefined>
  removeDocument<T extends Doc>(document: string | T, parameters?: RestApiCallParameters): Promise<Response | undefined>
  getView<T extends Doc>(viewName: string, parameters?: Omit<ViewParameters, "type">): Promise<ViewContainerManager<T>>
  verifyEmail(email: string): Promise<Response>
  validateEmailCheck(email: string, companyCode: string, surveyId: string): Promise<Response>
  validateEmailToken(email: string, token: string): Promise<Response>
  updateUserDetails(userDetails: SurveyUserDetails): Promise<Response>
  updateReportExtra(reportId: string, answerId: string, reportExtra?: Record<string, unknown>): Promise<Response>
}

export class SurveyActionsModule extends BaseModule implements SurveyActions {
  declare localization: Localization
  declare modelManager: ModelManager
  declare store: Store
  declare surveysApi: SurveysApi

  get moduleName() {
    return "SurveyActions"
  }

  get dependencies() {
    return ["Localization", "ModelManager", "Store", "SurveysApi"]
  }

  getDocument<T extends Doc>(id: string | T, documentType: string): Promise<T | undefined> {
    const params = typeof id === "string" ? { id } : { ...id }

    return this.coreActions.getDocument<T>(this.surveysApi, {
      ...params,
      type: documentType,
      acceptLanguage: this.acceptLanguage
    })
  }

  getView<T extends Doc>(
    viewName: string,
    parameters?: Omit<ViewParameters, "type">
  ): Promise<ViewContainerManager<T>> {
    return this.coreActions.getView<T>(this.surveysApi, {
      ...parameters,
      acceptLanguage: parameters?.acceptLanguage || this.acceptLanguage,
      type: viewName
    })
  }

  createDocument<T extends Doc>(
    document: Partial<T>,
    parameters: RestApiCallParameters<Partial<T>>
  ): Promise<T | undefined> {
    return this.coreActions.createDocument<T>(this.surveysApi, {
      ...parameters,
      acceptLanguage: parameters.acceptLanguage || this.acceptLanguage,
      type: parameters.type || document.__type!,
      body: document as T
    })
  }

  removeDocument<T extends Doc>(
    document: string | T,
    parameters: RestApiCallParameters
  ): Promise<Response | undefined> {
    parameters ??= {} as RestApiCallParameters
    parameters.acceptLanguage ||= this.acceptLanguage

    if (typeof document === "string") {
      parameters.id = document
    } else {
      parameters.id = document.id
      parameters.type ||= document.__type
    }

    return this.coreActions.removeDocument(this.surveysApi, parameters)
  }

  updateDocument<T extends Doc>(document: T, parameters: RestApiCallParameters<Partial<T>>): Promise<T | undefined> {
    return this.coreActions.updateDocument<T>(this.surveysApi, document, {
      ...parameters,
      acceptLanguage: parameters.acceptLanguage || this.acceptLanguage,
      type: parameters.type || document.__type,
      body: document
    })
  }

  updateUserDetails(body: SurveyUserDetails): Promise<Response> {
    const parameters: RestApiCallParameters<SurveyUserDetails> = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "update",
      body,
      type: "UserUpdate"
    }

    return this.surveysApi.restApiCall(parameters as RestApiCallParameters)
  }

  verifyEmail(email: string): Promise<Response> {
    const parameters: RestApiCallParameters<{ email: string }> = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "update",
      body: { email },
      type: "EmailVerify"
    }

    return this.surveysApi.restApiCall(parameters)
  }

  validateEmailCheck(emailAddress: string, companyCode: string, surveyId: string): Promise<Response> {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "get",
      email: emailAddress,
      company_code: companyCode,
      id: surveyId,
      survey_id: surveyId,
      search: { email: emailAddress, company_code: companyCode, survey_id: surveyId },
      type: "EmailValidateCheck"
    }

    return this.surveysApi.restApiCall(parameters)
  }

  validateEmailToken(emailAddress: string, tokenValue: string): Promise<Response> {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "create",
      email: emailAddress,
      magic_token: tokenValue,
      grant_type: "magic_token",
      search: [{ email: emailAddress }, { magic_token: tokenValue }, { grant_type: "magic_token" }],
      type: "EmailValidateToken"
    }

    return this.surveysApi.restApiCall(parameters)
  }

  updateReportExtra(reportId: string, answerId: string, reportExtra: Record<string, unknown>) {
    const parameters: RestApiCallParameters<{ report_extra: Record<string, unknown> }> = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "update",
      reportId,
      answerId,
      reportExtra: reportExtra,
      type: "SurveyResultsExtra",
      body: { report_extra: reportExtra }
    }

    return this.surveysApi.restApiCall(parameters)
  }

  private get acceptLanguage() {
    return this.localization.currentLanguage
  }
}
