import { Answer, Question } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../base/LogicComponent"
import NumberQuestionView from "./NumberQuestionView"

interface NumberQuestionProps extends LogicComponentProps {
  question: Question
  answer?: Answer
  disabled?: boolean
  labelStart?: string
  labelEnd?: string
  onOptionSelected: (questionId: string, optionId: string) => void
}

export default class NumberQuestion extends LogicComponent<NumberQuestionProps> {
  render() {
    super.render()

    const { answer, disabled, onOptionSelected, question, labelStart, labelEnd } = this.props

    const options = question.options.map(option => ({
      label: this.txt(option.title),
      value: option.id!.toString()
    }))

    const selected = answer?.options?.[0]?.id?.toString()

    return (
      <NumberQuestionView
        radioQuestionOptions={options}
        name={`question_${question.id}`}
        value={selected}
        disabled={disabled}
        labelStart={labelStart}
        labelEnd={labelEnd}
        onChange={value => onOptionSelected(question.id!, value)}
      />
    )
  }
}
