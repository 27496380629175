import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import { Circle } from "lib/ui/components/symbols"
import Typography from "lib/ui/components/typography/Typography"
import { getValueColor } from "lib/utils/report/WellbeingCommonReportUtils"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { reportBodyClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  name: string
  score: number | undefined
  comparisonScore: number | undefined
  steps: number
}

export default class ReportBigPictureLadder extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportBigPictureLadder"]
  }

  constructor(props) {
    super(props)

    setupStyles()
  }

  render() {
    const { name, score } = this.props

    return (
      <div className={styles.container}>
        <Typography useContrastColor className={classNames(reportBodyClass, styles.name)}>
          {name}
        </Typography>
        {this.renderLadder()}
      </div>
    )
  }

  private renderLadder() {
    return (
      <div className={styles.ladder}>
        {this.renderSteps()}
        {this.renderScore()}
        {this.renderPreviousScore()}
      </div>
    )
  }

  private renderSteps() {
    const { steps } = this.props
    const renderedSteps: React.JSX.Element[] = []

    for (let i = steps; i >= 1; i--) {
      renderedSteps.push(this.renderStep(i))
    }

    return renderedSteps
  }

  private renderStep(index: number) {
    const { steps } = this.props

    return (
      <div className={classNames(styles.ladderStep, index === 1 && styles.ladderBottomStep)} key={index}>
        <Typography useContrastColor className={classNames(reportBodyClass, styles.value)}>
          {index}
        </Typography>
      </div>
    )
  }

  private renderScore() {
    const { score } = this.props

    if (score === undefined) return

    return (
      <div style={{ bottom: `${3 * score + 0.25}rem` }} className={styles.selectionContainer}>
        <Circle
          className={styles.circle}
          shadow
          diameter="3.5rem"
          color={getValueColor(score / 2, this.theme).toString()}
        >
          <Typography className={classNames(reportBodyClass, styles.valueClassSelected)}>{score}</Typography>
        </Circle>
      </div>
    )
  }

  private renderPreviousScore() {
    const { comparisonScore } = this.props

    if (comparisonScore === undefined) return

    return (
      <div style={{ bottom: `${3 * comparisonScore + 3.125}rem` }} className={styles.selectionContainer}>
        <Circle
          className={styles.circle}
          shadow
          diameter="2.25rem"
          color={this.theme.colors.hintsa.alabaster.toString()}
        >
          <Typography className={classNames(reportBodyClass, styles.valueClassSelected)}>{comparisonScore}</Typography>
        </Circle>
      </div>
    )
  }
}

let styles

const setupStyles = () => {
  styles = stylesheet({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      height: "100%",
      width: "100%"
    },
    name: {
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
      // Give layout some stability when resizing
      minHeight: "3.5rem"
    },
    ladder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "12.5rem",
      padding: "0 1rem",
      marginTop: "2rem"
    },
    ladderStep: {
      display: "flex",
      height: "3rem",
      width: "100%",
      borderStyle: "solid",
      borderWidth: "0 2px 2px 2px",
      borderColor: ReportBigPictureLadder.theme.colors.hintsa.granite.toString(),
      alignItems: "center",
      justifyContent: "center"
    },
    ladderBottomStep: {
      borderBottomWidth: 0
    },
    selectionContainer: {
      display: "flex",
      position: "relative"
    },
    value: {
      fontWeight: "bold"
    },
    valueClassSelected: {
      fontWeight: "bold",
      fontSize: "24px"
    }
  })
}
