import * as csx from "csx"
import { stylesheet } from "typestyle"

import { ViewComponent } from "app/insights_admin/components/base/ViewComponent"
import { ViewComponentProps } from "core/components/base/ViewComponent"

interface MarkdownConverterProps extends ViewComponentProps {
  linksInNewWindow?: boolean
  imagesToFullWidth?: boolean
  imagesToHalfWidth?: boolean
  linksToAppDirectly?: boolean
  history?
  linkColor?: string
  className?: string
}

export default class MarkdownConverter extends ViewComponent<MarkdownConverterProps> {
  get componentName(): string[] {
    return ["admin_panel", "ui", "MarkdownConverter"]
  }
  private contentDiv?: HTMLDivElement | null

  constructor(props: MarkdownConverterProps) {
    super(props)

    setupStyles(props.linkColor)

    setTimeout(() => {
      this.update()
    }, 0)
  }

  componentDidUpdate(): void {
    this.update()
  }

  update() {
    this.updateLinksToNewWindow()
    this.updateImagesToFullWidth()
    this.updateImagesToHalfWidth()
    this.updateLinksToAppDirectly()
  }

  render() {
    return (
      <div className={(this.props.className, styles.linkColor)} ref={div => (this.contentDiv = div)}>
        {this.props.children}
      </div>
    )
  }

  private updateLinksToAppDirectly() {
    const { history } = this.props

    if (!this.props.linksToAppDirectly) return

    // Make all links open in a new browser window
    const anchors = this.contentDiv?.getElementsByTagName("a")
    if (!anchors) return

    const location = window.location
    const baseUrl = `${location.protocol}//${location.host}`

    for (const a of Array.from(anchors)) {
      if (a.href.startsWith(baseUrl)) {
        const page = a.href.replace(baseUrl, "")
        a.removeEventListener("click", event => {
          event.preventDefault()
          history.push(page)
        })

        a.addEventListener("click", event => {
          event.preventDefault()
          history.push(page)
        })
      }
    }
  }

  private updateLinksToNewWindow() {
    if (!this.props.linksInNewWindow) return

    // Make all links open in a new browser window
    const anchors = this.contentDiv?.getElementsByTagName("a")
    if (!anchors) return

    for (const a of Array.from(anchors)) {
      a.target = "_blank"
    }
  }

  private updateImagesToFullWidth() {
    if (!this.props.imagesToFullWidth) return

    // update all images to be of full width
    const images = this.contentDiv?.getElementsByTagName("img")
    if (!images) return

    for (const img of Array.from(images)) {
      img.style.width = "100%"
    }
  }

  private updateImagesToHalfWidth() {
    if (!this.props.imagesToHalfWidth) return

    // update all images to be of full width
    const images = this.contentDiv?.getElementsByTagName("img")
    if (!images) return

    for (const img of Array.from(images)) {
      img.style.width = "50%"
    }
  }
}

let styles
const setupStyles = (linkColor?: string) => {
  styles = stylesheet({
    linkColor: {
      $nest: {
        a: {
          color: linkColor && csx.important(linkColor)
        }
      }
    }
  })
}
