import type {
  OrganisationReport,
  PointCategoriesConfig,
  Question,
  ReportBreakdown,
  ReportInstance,
  ReportInstanceOrganisation,
  Results,
  Similarity,
  SubGroupResults,
  SurveyQuestionCategory,
  Tag,
  Team
} from "app/surveys_reports/modules/state/model/Model"
import type { CoreActions } from "core/modules/actions/CoreActions"
import type { Localization } from "core/modules/localization/Localization"
import type { Theme } from "core/modules/themes/Theme"
import { followUpIdentifyingQuestion, getQuestionCoblCategroyPrefix, ratingIndex } from "./WellbeingCommonReportUtils"

const maximumBarLabelLength = 30

export const getOptionColors = (theme: Theme) => {
  return [
    theme.colors.report.report_excellent,
    theme.colors.report.report_well_done,
    theme.colors.report.report_neutral,
    theme.colors.report.report_need_attention,
    theme.colors.report.report_need_extra_focus
  ]
}

export const getSelectedResults = (
  instance: ReportInstance | undefined,
  teamId?: string,
  tagId?: string
): Results | undefined => {
  if (teamId) {
    return tagId ? instance?.teams?.[teamId]?.tags?.[tagId] : instance?.teams?.[teamId]
  }

  if (tagId) return instance?.tags?.[tagId]

  return instance?.organisation
}

/** Combine point categories to match limits */
export const getValuesFromPointCategories = (pointCategories: number[], config: PointCategoriesConfig): number[] => {
  const { minimum, step } = config

  return pointCategories?.reduce((values: number[], value: number, index: number) => {
    const rating = ratingIndex(minimum + index * step)

    if (rating !== -1) {
      values[rating] = (values[rating] ?? 0) + value
    }

    return values
  }, [])
}

export const truncateBarGraphLabel = (label: string | string[]) => {
  const truncated = [...label]

  if (truncated[0].length >= maximumBarLabelLength) {
    truncated[0] = truncated[0].substring(0, maximumBarLabelLength - 1) + "…"
  }

  return label instanceof Array ? truncated : truncated[0]
}

export const isReportShownByCategory = (questions: Question[], minQuestionsToShowByCategory: number) => {
  if (questions.length < minQuestionsToShowByCategory) {
    return false
  }

  // Don't show by category if any question has category missing
  return !questions.find(question => !question.category?.en)
}

export const getInsufficientDataReason = (
  doc: Team | Tag,
  similarities: Similarity[] | undefined,
  teams: Team[] | undefined,
  tags: Tag[] | undefined,
  appName: string,
  localization: Localization
) => {
  if (!similarities || !teams || !tags || !doc) return ""

  const similarTeams = getSimilarities(doc, "team", similarities, teams, tags).join(",")
  const similarTags = getSimilarities(doc, "tag", similarities, teams, tags).join(",")

  if (similarTeams && similarTags) {
    return localization.txt(appName, "report", "reportTools", "too_similar_to_teams_and_tags", [
      similarTeams,
      similarTags
    ])
  }
  if (similarTeams) {
    return localization.txt(appName, "report", "reportTools", "too_similar_to_teams", [similarTeams])
  }

  if (similarTags) {
    return localization.txt(appName, "report", "reportTools", "too_similar_to_tags", [similarTags])
  }
}

export const followupAggregatedReport = (questions: Question[]) => {
  return questions.some(q => q.key === followUpIdentifyingQuestion)
}

const getSimilarities = (
  source: Team | Tag,
  type: "team" | "tag",
  similarities: Similarity[],
  teams: Team[],
  tags: Tag[]
): string[] => {
  if (!similarities || !teams || !tags || !source) return []

  const sourceType = source.__type!.toLowerCase()
  const sourceId = source.id!.toString()
  const similarByType = similarities
    .filter(({ document }) => document.type.toLowerCase() === sourceType && document.id.toString() === sourceId)
    .flatMap(similarity => similarity.similar)
    .filter(similar => similar.type === type)

  const similarIds = similarByType.map(similar => similar.id!.toString())
  const docsOfType = (type === "team" ? teams : tags).filter(doc => similarIds.includes(doc.id!.toString()))

  return docsOfType.map(doc => doc.name)
}

export const processOrganisationReport = (
  organisationReport: OrganisationReport,
  reportId: string,
  coreActions: CoreActions
) => {
  if (organisationReport.processed) return organisationReport

  organisationReport = Object.assign({}, organisationReport)
  organisationReport.id = reportId

  const questions = organisationReport.questions.map(
    question => coreActions.getDocumentLocal<Question>(question.id!, question.__type!)!
  )

  processQuestionCategories(organisationReport, questions)
  processQuestionBreakdowns(organisationReport, questions)
  processTagCategories(organisationReport, coreActions)
  processCategoryBreakdowns(organisationReport, questions)

  organisationReport.processed = true

  return organisationReport
}

export const aggregatedComparisonResult = (organisationReport: OrganisationReport) => {
  // Report history is explicitly specified when starting a survey (comparison report).
  // Therefore we can assume that if there is more than one instance, it is a comparison report.
  return organisationReport.instances.length > 1 ? organisationReport.instances[1] : undefined
}

const processQuestionBreakdowns = (organisationReport: OrganisationReport, questions: Question[]) => {
  const addBreakdownAveragePoints = (breakdown: ReportBreakdown, question: Question) => {
    // Get the average points of results. Divide by 100 because answer_percentages are from 0 to 100
    breakdown.average_points =
      question.options
        .map((value, i) => breakdown.answer_percentages[i] * value.points!)
        .reduce((value, result) => result + value, 0) / 100
  }

  for (const instance of organisationReport.instances) {
    if (instance.organisation.insufficient_data) continue

    for (const questionId of Object.keys(instance.organisation.breakdown)) {
      const question = questions.find(q => {
        return q.id === questionId || q.key === questionId
      })
      if (!question) continue

      addBreakdownAveragePoints(instance.organisation.breakdown[questionId], question)

      for (const team of organisationReport.teams) {
        const instanceTeam = instance.teams[team.id!]
        if (!instanceTeam || instanceTeam.insufficient_data) continue

        const teamBreakdown = instanceTeam.breakdown[questionId]
        if (!teamBreakdown) continue

        addBreakdownAveragePoints(teamBreakdown, question)

        if (instanceTeam.tags) {
          organisationReport.filterTagsAvailable = true

          for (const key of Object.keys(instanceTeam.tags)) {
            if (instanceTeam.tags[key].insufficient_data) continue

            addBreakdownAveragePoints(instanceTeam.tags[key].breakdown[questionId], question)
          }
        }
      }

      for (const tag of organisationReport.tags) {
        const instanceTag = instance.tags[tag.id!]
        if (!instanceTag || instanceTag.insufficient_data) continue

        const tagBreakdown = instanceTag.breakdown[questionId]
        if (!tagBreakdown) continue

        addBreakdownAveragePoints(tagBreakdown, question)
      }
    }
  }
}

const processQuestionCategories = (organisationReport: OrganisationReport, questions: Question[]) => {
  const categories = Object.assign({}, organisationReport).question_categories as SurveyQuestionCategory[]

  for (const category of categories) {
    // Get category data from first non-custom question
    const categoryQuestion = questions.find(q => !q.custom && q.category_object?.id === category.id)
    const prefix = (!category.custom && getQuestionCoblCategroyPrefix(categoryQuestion)) || undefined

    category.categoryPrefix = prefix
    category.isCoblElement = !!prefix
  }

  return categories
}

const processCategoryBreakdowns = (organisationReport: OrganisationReport, questions: Question[]) => {
  if (!organisationReport.question_categories) return

  const addCategoryBreakdown = (instance: ReportInstanceOrganisation | SubGroupResults) => {
    if (!instance.breakdown) return

    instance.category_breakdown = {}

    for (const category of organisationReport.question_categories) {
      const categoryQuestions = questions.filter(question => question.category_object?.id === category.id)
      if (categoryQuestions.length === 0) continue

      const activeCategoryResults = categoryQuestions
        .map(question => {
          return instance.breakdown[question.id!] || instance.breakdown[question.key!]
        })
        .filter(Boolean)

      const average_points =
        activeCategoryResults.map(breakdown => breakdown.average_points).reduce((a, b) => a + b, 0) /
        activeCategoryResults.length

      instance.category_breakdown[category.id!] = { average_points, answer_percentages: [] }

      // Count answer percentages
      for (let i = 0; i < 5; i++) {
        instance.category_breakdown[category.id!].answer_percentages[i] =
          categoryQuestions
            .map(question => {
              return instance.breakdown[question.id!] || instance.breakdown[question.key!]
            })
            .filter(Boolean)
            .map(breakdown => breakdown.answer_percentages[i])
            .reduce((a, b) => a + b, 0) / categoryQuestions.length
      }
    }
  }

  for (const instance of organisationReport.instances) {
    if (instance.organisation.insufficient_data) continue

    addCategoryBreakdown(instance.organisation)

    for (const team of organisationReport.teams) {
      const instanceTeam = instance.teams[team.id!]
      if (!instanceTeam || instanceTeam.insufficient_data) continue

      addCategoryBreakdown(instanceTeam)

      for (const tag of Object.values(instanceTeam.tags || [])) {
        if (tag.insufficient_data) continue

        addCategoryBreakdown(tag)
      }
    }

    for (const tag of organisationReport.tags) {
      const instanceTag = instance.tags[tag.id!]
      if (!instanceTag || instanceTag.insufficient_data) continue

      addCategoryBreakdown(instanceTag)
    }
  }
}

const processTagCategories = (organisationReport: OrganisationReport, coreActions: CoreActions) => {
  const tagCategories = {}
  for (const tagLink of organisationReport.tags) {
    const tag = coreActions.getDocumentLocal<Tag>(tagLink.id, "Tag")

    if (tag?.category_name) tagCategories[tag.category_name] = true
  }

  organisationReport.tagCategories = Object.keys(tagCategories)
}
