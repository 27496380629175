import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import { Triangle } from "lib/ui/components/symbols"
import Comparison from "lib/ui/components/symbols/Comparison"
import Typography from "lib/ui/components/typography/Typography"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../base/ViewComponent"
import { reportBodyClass, reportSmallBodyClass, reportSmallHeadingClass } from "../WellbeingReport"

// What score causes what color. Not that you don't need to specify all
export interface ScoreColorSetpoints {
  red?: number
  orange?: number
  yellow?: number
  lightGreen?: number
  darkGreen?: number
}

interface Props extends ViewComponentProps {
  score: number | undefined
  scoreComparison?: number | undefined
  minScore: number
  maxScore: number
  scoreDescription?: string
  scoreDescriptionComparison?: string
  scoreColorSetpoints: ScoreColorSetpoints
  scoreColorCalculationMethod: "index" | "value"
  title?: string
  // Controls whether the top score area is shown
  showTopScoreArea?: boolean
  // Controls whether the bottom score area is shown
  showBottomScoreArea?: boolean
  // Controls the type of score shonw in the bottom area
  bottomScoreType?: "number" | "answerOption"
  // Controls whether the score index below the horizontal bar is shown
  showScoreIndex?: boolean
  // Controls subheading type, index number of score [score/max] or text.
  subheadingContent?: "index" | "text"
  className?: string
}

export default class ScoreMeter extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ui", "ScoreMeter"]
  }

  constructor(props) {
    super(props)
    setupStyles()
  }

  render() {
    super.render()

    const { className, score } = this.props

    if (score === undefined) return null

    return (
      <div className={classNames(styles.outerContainer, className)}>
        {this.renderScoreAbove()}
        {this.renderComparisonTriangle()}
        {this.renderMeter()}
        {this.renderScoreBelow()}
      </div>
    )
  }

  private renderComparisonTriangle() {
    const { score, scoreComparison } = this.props

    if (scoreComparison === undefined || score === scoreComparison) return null

    return (
      <div
        style={{
          left: `calc(${((scoreComparison - 1) / this.props.maxScore) * 100 + 50 / this.props.maxScore}% - 0.5rem)`
        }}
        className={styles.scoreTriangleContainer}
      >
        <Triangle direction="down" width={1} height={1} color={this.theme.colors.hintsa.alabaster} />
      </div>
    )
  }

  private renderScoreAbove() {
    const { score, scoreComparison, maxScore, showTopScoreArea, title } = this.props

    if (!showTopScoreArea && !title) return <div />
    return (
      <div className={styles.scoreContainer}>
        <div>
          <Typography useContrastColor className={classNames(reportBodyClass, styles.title)}>
            {title ?? this.txt("yourScore")}
          </Typography>
        </div>
        {showTopScoreArea && (
          <div className={styles.scoreAboveContainer}>
            <Comparison score={score} comparisonScore={scoreComparison} className={styles.comparison} />
            <Typography component="span" className={reportSmallHeadingClass} useContrastColor>
              {score}
            </Typography>
            <Typography component="span" useContrastColor className={reportBodyClass}>{`/${maxScore}`}</Typography>
          </div>
        )}
      </div>
    )
  }

  private renderScoreBelow() {
    const { showBottomScoreArea, score, maxScore, bottomScoreType, scoreDescription, scoreDescriptionComparison } =
      this.props

    if (!showBottomScoreArea) return null

    if (bottomScoreType === "answerOption")
      return (
        <div className={styles.scoreBelow}>
          <Typography useContrastColor className={reportSmallBodyClass}>
            {`${scoreDescription ?? ""}`}
          </Typography>
          {scoreDescriptionComparison && scoreDescriptionComparison !== scoreDescription && (
            <Typography
              useContrastColor
              className={classNames(reportSmallBodyClass, styles.scoreDescriptionComparison)}
            >
              {this.txt("scoreDescriptionComparison", [scoreDescriptionComparison])}
            </Typography>
          )}
        </div>
      )

    return (
      <div className={styles.scoreBelow}>
        <Typography useContrastColor className={reportSmallBodyClass}>
          {`${score}/${maxScore}`}
        </Typography>
      </div>
    )
  }

  private renderMeter() {
    return <div className={styles.meterContainer}>{this.renderMeterValues()}</div>
  }

  private renderMeterValues() {
    const { minScore, maxScore, score, showScoreIndex, scoreColorCalculationMethod, scoreColorSetpoints } = this.props

    const results: React.JSX.Element[] = []
    for (let i = minScore; i <= maxScore; i++) {
      results.push(
        <div key={i} style={{ width: `${100 / (maxScore - minScore + 1)}%` }}>
          <div
            className={classNames(
              styles.meterValue,
              i === minScore && styles.meterFirstValue,
              i === maxScore && styles.meterLastValue,
              i > minScore && i < maxScore && styles.meterMidValue,
              scoreColorCalculationMethod === "index" &&
                scoreColorSetpoints.red !== undefined &&
                i >= scoreColorSetpoints.red &&
                styles.meterRed,
              scoreColorCalculationMethod === "index" &&
                scoreColorSetpoints.orange !== undefined &&
                i >= scoreColorSetpoints.orange &&
                styles.meterOrange,
              scoreColorCalculationMethod === "index" &&
                scoreColorSetpoints.yellow !== undefined &&
                i >= scoreColorSetpoints.yellow &&
                styles.meterYellow,
              scoreColorCalculationMethod === "index" &&
                scoreColorSetpoints.lightGreen !== undefined &&
                i >= scoreColorSetpoints.lightGreen &&
                styles.meterLightGreen,
              scoreColorCalculationMethod === "index" &&
                scoreColorSetpoints.darkGreen !== undefined &&
                i >= scoreColorSetpoints.darkGreen &&
                styles.meterDarkGreen,
              scoreColorCalculationMethod === "value" &&
                scoreColorSetpoints.red !== undefined &&
                score! >= scoreColorSetpoints.red &&
                styles.meterRed,
              scoreColorCalculationMethod === "value" &&
                scoreColorSetpoints.orange !== undefined &&
                score! >= scoreColorSetpoints.orange &&
                styles.meterOrange,
              scoreColorCalculationMethod === "value" &&
                scoreColorSetpoints.yellow !== undefined &&
                score! >= scoreColorSetpoints.yellow &&
                styles.meterYellow,
              scoreColorCalculationMethod === "value" &&
                scoreColorSetpoints.lightGreen !== undefined &&
                score! >= scoreColorSetpoints.lightGreen &&
                styles.meterLightGreen,
              scoreColorCalculationMethod === "value" &&
                scoreColorSetpoints.darkGreen !== undefined &&
                score! >= scoreColorSetpoints.darkGreen &&
                styles.meterDarkGreen,
              i > score! && styles.meterNoScore
            )}
          />
          {showScoreIndex && (
            <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.meterScore)}>
              {i}
            </Typography>
          )}
        </div>
      )
    }

    return results
  }
}

let styles

const setupStyles = () => {
  styles = stylesheet({
    title: {
      fontWeight: "bold"
    },
    outerContainer: {
      width: "100%"
    },
    scoreContainer: {
      display: "flex",
      marginBottom: "-0.5rem",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "100%"
    },
    scoreTriangleContainer: {
      position: "relative"
    },
    meterContainer: {
      display: "flex"
    },
    scoreAboveContainer: {
      display: "flex",
      alignItems: "baseline"
    },
    comparison: {
      alignSelf: "center",
      marginRight: "0.5rem"
    },
    meterValue: {
      backgroundColor: ScoreMeter.theme.colors.hintsa.alabaster.toString(),
      height: "0.5rem",
      width: "100%"
    },
    meterFirstValue: {
      borderTopLeftRadius: "0.25rem",
      borderBottomLeftRadius: "0.25rem"
    },
    meterLastValue: {
      borderTopRightRadius: "0.25rem",
      borderBottomRightRadius: "0.25rem",
      borderLeft: "1px solid",
      borderLeftColor: ScoreMeter.theme.colors.hintsa.charcoal.toString()
    },
    meterMidValue: {
      borderLeft: "1px solid",
      borderLeftColor: ScoreMeter.theme.colors.hintsa.charcoal.toString()
    },
    meterRed: {
      backgroundColor: ScoreMeter.theme.colors.report.report_need_extra_focus.toString()
    },
    meterOrange: {
      backgroundColor: ScoreMeter.theme.colors.report.report_need_attention.toString()
    },
    meterYellow: {
      backgroundColor: ScoreMeter.theme.colors.report.report_neutral.toString()
    },
    meterLightGreen: {
      backgroundColor: ScoreMeter.theme.colors.report.report_well_done.toString()
    },
    meterDarkGreen: {
      backgroundColor: ScoreMeter.theme.colors.report.report_excellent.toString()
    },
    meterNoScore: {
      backgroundColor: ScoreMeter.theme.colors.hintsa.alabaster.toString(),
      opacity: 0.4
    },
    meterScore: {
      margin: "0.25rem 0 0 0",
      display: "flex",
      justifyContent: "center"
    },
    scoreBelow: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "-0.5rem"
    },
    scoreDescriptionComparison: {
      marginTop: "-0.75rem",
      color: `${ScoreMeter.theme.colors.hintsa.alabaster.toString()}!important`
    }
  })
}
