import { Answer, Question } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../base/LogicComponent"
import CheckboxQuestionView from "./CheckboxQuestionView"

interface CheckboxQuestionProps extends LogicComponentProps {
  answer?: Answer
  question: Question
  onOptionsSelected: (questionId: string, optionIds: string[]) => void
  onQuestionAnswerNoneOfTheAbove: (questionId: string) => void
}

export default class CheckboxQuestion extends LogicComponent<CheckboxQuestionProps> {
  render() {
    super.render()

    const { answer, question } = this.props
    const selected = answer?.options?.map(({ id }) => String(id))

    return (
      <CheckboxQuestionView
        options={question.options}
        selected={selected}
        showNoneOfTheAbove={question.min_required_options === 0}
        onChange={this.onSelected}
        onNoneOfTheAbove={this.onQuestionAnswerNoneOfTheAbove}
      />
    )
  }

  private onSelected = (optionIds: string[]) => {
    this.props.onOptionsSelected(this.props.question.id!, optionIds)
  }

  private onQuestionAnswerNoneOfTheAbove = () => {
    const { onQuestionAnswerNoneOfTheAbove, question } = this.props

    onQuestionAnswerNoneOfTheAbove(question.id!)
  }
}
