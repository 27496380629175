import { type PropsWithChildren } from "react"

import Button, { type ButtonProps } from "lib/ui/components/form/Button"

interface Props extends ButtonProps {
  isDownloadingPdf: boolean
  onClick: () => void
}

export function DownloadPdfButton({ children, isDownloadingPdf, onClick, ...props }: PropsWithChildren<Props>) {
  return (
    <Button loading={isDownloadingPdf} onClick={onClick} {...props}>
      {children}
    </Button>
  )
}
