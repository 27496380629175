import { SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import { TranslatedString } from "core/modules/state/model/Model"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import { COBL_CATEGORIES } from "lib/utils/report/WellbeingCommonReportUtils"
import {
  answersByCategory,
  getCategoryAverageScore,
  getCategoryMaxPoints as getCategoryMaxScore
} from "lib/utils/report/WellbeingPersonalReportUtils"
import React from "react"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportSmallHeadingClass } from "../../WellbeingReport"
import ReportCoblCategory from "./ReportCoblCategory"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  reportVariant: ReportVariant
}

const maxCategoryPoints = 5

export default class ReportSectionCoblCategories extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionCoblCategories"]
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { reportVariant } = this.props

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}

        <Grid container spacing={2}>
          <Grid spacer spacerSize={reportVariant === "mobile" ? 6 : 12} />
          {this.renderTitle()}
          {this.renderCategories()}
        </Grid>
      </>
    )
  }

  private renderTitle() {
    return (
      <Grid sm={8}>
        <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.title)}>
          {this.txt("title")}
        </Typography>
      </Grid>
    )
  }

  private renderCategories() {
    const { answer, comparison, reportVariant } = this.props

    return COBL_CATEGORIES.map((categoryPrefix, i) => {
      const answers = answersByCategory(answer.answers, categoryPrefix)
      const comparisonAnswers = comparison?.answers

      return (
        answers.length > 0 && (
          <React.Fragment key={i}>
            <Grid xs={12} md={4} key={categoryPrefix}>
              <ReportCoblCategory
                categoryName={answers[0].category ?? ({} as TranslatedString)}
                categoryDescription={answers[0].category_description ?? ({} as TranslatedString)}
                iconUrl={`${this.appConfig.imagesRoot}/cobl_categories/icon_${categoryPrefix}_w.svg`}
                score={getCategoryAverageScore(answers, categoryPrefix) ?? 0}
                comparisonScore={getCategoryAverageScore(comparisonAnswers, categoryPrefix) ?? 0}
                maxScore={getCategoryMaxScore(answers, categoryPrefix) ?? 0}
                variant={reportVariant}
              />
            </Grid>
            {i % 3 === 0 && reportVariant !== "mobile" && <Grid spacer spacerSize={2} />}
          </React.Fragment>
        )
      )
    }).filter(Boolean)
  }
}

const styles = stylesheet({
  title: {
    marginTop: 0,
    marginRight: "2rem"
  }
})
