import { LogicComponentProps } from "core/components/base/LogicComponent"

import { LogicComponent } from "../base/LogicComponent"
import Dropdown, { type DropdownOption } from "../form/Dropdown"

interface LanguageSelectorDropdownProps extends LogicComponentProps {
  language?: string
  setLanguage: (language: string) => void
  id?: string
  className?: string
}

export default class LanguageSelectorDropdown extends LogicComponent<LanguageSelectorDropdownProps> {
  get componentName(): string[] {
    return ["settings", "LanguageSelectorDropdown"]
  }

  render() {
    const { language, id, setLanguage } = this.props

    const options: DropdownOption[] = this.localization.enabledLanguages.map(language => ({
      label: `${this.txt("languages", language, "name")} (${this.txt("languages", language, "short_name")})`,
      value: language
    }))

    return (
      <Dropdown
        options={options}
        value={language}
        allowEmptySelection={false}
        label={this.txt("chooseLanguage")}
        id={id || "languageSelection"}
        onChange={setLanguage}
      />
    )
  }
}
